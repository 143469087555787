import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ColorRing } from  'react-loader-spinner'

const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        getBlogs();
    }, [])
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
    })
    const [looading,setLoading] = useState(true)
    const [res, setRes] = useState(null)
    const [blogs, SetNewBlogs] = useState([])

    const getBlogs = async () => {
        
        await axios.get(`https://backend.xampra.com/backend/blogList.php`,{headers: {
            // 'application/json' is the modern content-type for JSON, but some
            'content-type': 'application/json'
          }}).then(response => {
            setLoading(false)
            SetNewBlogs(response.data);
        }).catch(err => {
            if (err) {
                setRes(err.response.data)
            }
        });

    }
    return (
        <>
            <Helmet>
                <title>Blogs | Xampra One Stop IT Solutions</title>
                <meta name="description" content="We Care Your any IT SolutionMake your work easier with an integrated ecosystem that lets all departments work properly together." />
                <meta name="keywords" content="Website Development, Web Application Development, Android App Development" />
                <meta name="author" content="Abdul Hanan" />
                <link rel= "canonical" href= "https://www.xampra.com/Blogs" />
            </Helmet>
            <section className="page-header position-relative overflow-hidden ptb-120 bg-dark bg10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h1 className="display-5 fw-bold">Blogs</h1>
                        </div>
                    </div>
                    <div className="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"></div>
                </div>
            </section>
            <section className="contact-promo ptb-120">
                <div className="container">
                    <div className="row justify-content-center">

                    <ColorRing
                        visible={looading}
                        height="100"
                        width="100"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#4d3c8e', '#824ee4', '#8d50ff', '#9958ec', '#ae72ef']}
                    />

                        {blogs.map((data,index) => {
                            return (
                                <div className="col-lg-4 col-md-6" style={{marginTop:"10px"}}>
                                    <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow text-center d-flex flex-column h-100">
                                    <img src={`https://backend.xampra.com/backend/uploads/${data.blogImg}`} style={{width:"100%"}}/>
                                        <div className="contact-promo-info mb-1 mt-4">
                                            <h5>{data.blogName}</h5>
                                        </div>
                                        <Link to={`blogDetail/${data.blogUrl}`} className="btn btn-link mt-auto">Read More</Link>
                                    </div>
                                </div>
                            )
                        })}



                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog;
