const home = '/';
const about = '/about';
const contact = '/Contact';
const uIUXDDesigning = '/UI-UX-Designing';
const webAppDevelopment = '/Web-Application-Development';
const webSiteDevelopment = '/Web-Site-Development';
const cRMDevelopment = '/CRM-Development';
const intigrations = '/Integrations';
const mobileAppDevelopment = '/Mobile-Application-Development';
const digitalMarketing = '/Digital-Marketing';
const eRPDevelopment = '/ERP-Development';
const sEO = '/SEO';
const graphicDesigning = '/GraphicDesigning';
const contentCreation = '/ContentCreation';
const allServices = '/Services';
const termsConditions = "Terms&Conditions";
const blog = "Blogs";
const url = "https://www.xampra.com/"
// const url = "http://localhost:3000/"
const SetAppointmentUrl = "SetMeeting" 
const WebSerices = [
    {
        id : '1',
        name : 'Mobile Applications',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/mobile-development.webp',
        link : `${mobileAppDevelopment}`,
        metaTitle : 'Best Mobile App Development Company USA – Xampra',
        metaKeyword : 'Xampra is one of the best mobile app development company in the USA, complete with case studies, user reviews, hourly rates, team size, and more.',
        shortDesc: 'Xampra is one of the best mobile app developers in the industry, complete with case studies, user reviews, hourly rates, team size, and more.'
    },
    {
        id : '2',
        name : 'Web Applications',
        icon : 'fas fa-address-book',
        logo : 'assets/logoIcon/computer.webp',
        link : `${webAppDevelopment}`,
        metaTitle : 'Best Web Development Company USA | Website developers',
        metaKeyword : 'Programming for web, Google web server, Web application development Canada, Progressive web app, Bootstrap spring, Web application, Web application US',
        shortDesc: 'Xampra provides Web application services in USA and Canada using modern frameworks like React, Angular, Typescript, Html, PHP, Laravel, and more.'
    },
    {
        id : '3',
        name : 'Web-Site Development',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/computer.png',
        link : `${webSiteDevelopment}`,
        metaTitle : 'Best website builder service USA | Top Website Developers',
        metaKeyword : 'Freelance web designer, Freelance web developer low price, Web designing near me, Website coder, Agency web design, Website designing company, Web design company, Website development',
        shortDesc: 'Xampra specializes in website design and development services in Canada. Our website developers provide the best website to grow your business.'
    },
    {
        id : '4',
        name : 'CRM Development',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/crm.webp',
        link : `${cRMDevelopment}`,
        metaTitle : 'Best Custom CRM Software Development Company – Xampra',
        metaKeyword : 'Crm software development company, Crm development company, Custom crm development US, Crm developers near me, CRM development',
        shortDesc: 'Xampra is a one-stop CRM development company dedicated to delivering best-grade client management solutions that generate business value.'
    },
    {
        id : '5',
        name : 'ERP Development',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/erp.webp',
        link : `${eRPDevelopment}`,
        metaTitle : 'Best Custom ERP Software Development Services USA – Xampra',
        metaKeyword : 'Custom ERP software development, ERP software development Canada, ERP software development company, ERP development',
        shortDesc: 'Enterprise resource planning (ERP) development firm Xampra offers a full range of services, from consultancy to the public release of your ERP.'
    },
    {
        id : '6',
        name : 'Third Party Integrations',
        icon : 'fas fa-rocket-launch',
        logo : 'assets/logoIcon/distributed.webp',
        link : `${intigrations}`,
        metaTitle : 'Third party integration services USA and Canada',
        metaKeyword : 'Third party system integration, Salesforce third party integration, Third party Integration',
        shortDesc: 'Boost your business with effortless third-party integration, connecting systems, apps, and data. Streamline operations and drive growth. Stay ahead.'
    }
]
const DigitalServices = [
    {
        id : '1',
        name : 'UI/UX Designing',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/ux-design.webp',
        link : `${uIUXDDesigning}`,
        metaTitle : 'Best UI\UX designing company USA - Top UI\UX Designer',
        metaKeyword : 'Google UX design, User interface design User experience design, UX design, UI UX designing',
        shortDesc: 'Hire the best UI UX design agency for your project. Xampra is a top UI/UX designer provider company in Canada. They make your product more attractive.'
    },
    {
        id : '2',
        name : 'SEO',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/seo.webp',
        link : `${sEO}`,
        metaTitle : 'Best SEO Company USA | SEO Services Canada | SEO Agency',
        metaKeyword : 'Best SEO company primelis, SEO marketing, google SEO, SEO services US and Canada, SEO company, SEO ',
        shortDesc: 'Xampra provide many SEO services you can buy to help you boost your online website. These include on-page, off page, technical SEO, Keywords.'
    },
    {
        id : '3',
        name : 'Digital Media Marketing',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/social-media.webp',
        link : `${digitalMarketing}`,
        metaTitle : 'Social Media Marketing USA – Top Digital Marketing Agency',
        metaKeyword : 'Digital marketing newspaper, Digital media marketing, Social media agency, Social media marketing agency, Digital media marketing',
        shortDesc: 'Xampra provides digital marketing services across the US and Canada. They offer social media services, SEO, and other services.'
    },
    {
        id : '4',
        name : 'Graphic Designing',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/graphic-design.webp',
        link : `${graphicDesigning}`,
        metaTitle : 'Top Graphic Design Agencies US - Designing Company Canada',
        metaKeyword : 'Graphic design portfolios, Motion designer US, Creative designer, Motion graphics, Digital designer near me, Graphic designing',
        shortDesc: 'Our graphic design company provides professional expertise and top-quality graphic design services in all over the world. They help grow your business.'
    },
    {
        id : '5',
        name : 'Content Writing',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/writing.webp',
        link : `${contentCreation}`,
        metaTitle : 'Professional Content Writing Services USA | Best content writer',
        metaKeyword : 'Content writing services Canada, SEO Copywriting, Article rewriting, Freelance writer US, Content writing',
        shortDesc: 'Xampra provides High-quality content writing services you can trust. Our expert writers deliver engaging articles, blogs, and web copy that drive results.'
    },
    // {
    //     id : '5',
    //     name : 'Lead Generation',
    //     icon : 'far fa-fingerprint',
    //     logo : 'assets/logoIcon/user-engagement.png',
    //     link : `${home}`,
    //     metaTitle : 'Lead Generation',
    //     metaKeyword : 'Lead Generation',
    //     shortDesc: 'Lead Generation'
    // },
    // {
    //     id : '6',
    //     name : 'Video Editor',
    //     icon : 'far fa-fingerprint',
    //     logo : 'assets/logoIcon/video-editor.png',
    //     link : `${home}`,
    //     metaTitle : 'Video Editor',
    //     metaKeyword : 'Video Editor',
    //     shortDesc: 'Video Editor'
    // },
]
const Technologies = [
    {
        id : '1',
        name : 'React JS & React Native',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/reactjs.webp',
        link : `${home}`,
        metaTitle : 'React JS & React Native',
        metaKeyword : 'React JS & React Native',
        shortDesc: 'React JS & React Native'
    },
    {
        id : '2',
        name : 'Vue Js',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/vuejs.webp',
        link : `${home}`,
        metaTitle : 'Vue Js',
        metaKeyword : 'Vue Js',
        shortDesc: 'Vue Js'
    },
    {
        id : '3',
        name : 'Next Js',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/nextjs.webp',
        link : `${home}`,
        metaTitle : '',
        metaKeyword : '',
        shortDesc: ''
    },
    {
        id : '4',
        name : 'Angular',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/angular.webp',
        link : `${home}`,
        metaTitle : 'Flutter Applications',
        metaKeyword : 'Flutter Applications',
        shortDesc: 'Flutter Applications'
    },
    {
        id : '5',
        name : 'Node JS',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/nodejs.webp',
        link : `${home}`,
        metaTitle : 'Node JS',
        metaKeyword : 'Node JS',
        shortDesc: 'Node JS'
    },
    {
        id : '6',
        name : 'Python',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/python.webp',
        link : `${home}`,
        metaTitle : 'Python',
        metaKeyword : 'Python',
        shortDesc: 'Python'
    },
    {
        id : '7',
        name : 'Block Chain',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/blockchane.webp',
        link : `${home}`,
        metaTitle : 'Block Chain',
        metaKeyword : 'Block Chain',
        shortDesc: 'Block Chain'
    },
    {
        id : '8',
        name : 'ASP.NET',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/asp.webp',
        link : `${home}`,
        metaTitle : 'ASP.NET',
        metaKeyword : 'ASP.NET',
        shortDesc: 'ASP.NET'
    },
    {
        id : '9',
        name : 'Ruby on Rails',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/rubbyonrails.webp',
        link : `${home}`,
        metaTitle : 'Ruby on Rails',
        metaKeyword : 'Ruby on Rails',
        shortDesc: 'Ruby on Rails'
    },
    {
        id : '10',
        name : 'PHP',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/pho.webp',
        link : `${home}`,
        metaTitle : 'PHP',
        metaKeyword : 'PHP',
        shortDesc: 'PHP'
    },
    {
        id : '11',
        name : 'Laravel',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/laravel.webp',
        link : `${home}`,
        metaTitle : 'Laravel',
        metaKeyword : 'Laravel',
        shortDesc: 'Laravel'
    },
    {
        id : '12',
        name : 'WordPress',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/wordpress.webp',
        link : `${home}`,
        metaTitle : 'WordPress',
        metaKeyword : 'WordPress',
        shortDesc: 'WordPress'
    },
    {
        id : '13',
        name : 'shopify',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/shopify.webp',
        link : `${home}`,
        metaTitle : 'shopify',
        metaKeyword : 'shopify',
        shortDesc: 'shopify'
    },
    {
        id : '14',
        name : 'Flutter Applications',
        icon : 'fas fa-fingerprint',
        logo : 'assets/logoIcon/flutter.webp',
        link : `${home}`,
        metaTitle : 'Flutter Applications',
        metaKeyword : 'Flutter Applications',
        shortDesc: 'Flutter Applications'
    },

]
const IntigrationLIst = [
    {
        id:'1',
        name:'Google Drive',
        logo:'assets/img/integations/1.png'
    },
    {
        id:'2',
        name:'Google Maps',
        logo:'assets/img/integations/googlemaps.jpg'
    },
    {
        id:'3',
        name:'Facebook',
        logo:'assets/img/integations/facebook.png'
    },
    {
        id:'4',
        name:'Slack',
        logo:'assets/img/integations/9.png'
    },
    {
        id:'5',
        name:'Linkedin',
        logo:'assets/img/integations/linkedin.png'
    },
    {
        id:'6',
        name:'Mail Chimp',
        logo:'assets/img/integations/mailchimp.png'
    },
    {
        id:'7',
        name:'Figma',
        logo:'assets/img/integations/figma.png'
    },
    {
        id:'8',
        name:'Google Analytics',
        logo:'assets/img/integations/google-analytics.png'
    },
    {
        id:'9',
        name:'Instagram',
        logo:'assets/img/integations/instagram.png'
    },
    {
        id:'10',
        name:'Dropbox',
        logo:'assets/img/integations/dropbox-2.png'
    },
    {
        id:'11',
        name:'Marekto',
        logo:'assets/img/integations/marekto.png'
    },
    {
        id:'12',
        name:'Paypal',
        logo:'assets/img/integations/paypal.png'
    },
    {
        id:'13',
        name:'Stripe',
        logo:'assets/img/integations/stripe.png'
    },
    {
        id:'14',
        name:'Braintree',
        logo:'assets/img/integations/braintree.png'
    },
    {
        id:'15',
        name:'Amazon Pay',
        logo:'assets/img/integations/amazonpay.png'
    }
]


export {home,about,contact,WebSerices,
    Technologies,DigitalServices,
    uIUXDDesigning,webAppDevelopment,webSiteDevelopment,
    cRMDevelopment,intigrations,IntigrationLIst,mobileAppDevelopment,
    digitalMarketing,eRPDevelopment,sEO,graphicDesigning,contentCreation,allServices,termsConditions,blog,url,SetAppointmentUrl}
