import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {contact} from "../constants/helpers";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {DigitalServices,url} from "../constants/helpers";
const UIUXDDesigning = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let data = DigitalServices.find(el => el.id === "1");
    return(
     <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
                <link rel= "canonical" href= "https://www.xampra.com/UI-UX-Designing" />
            </Helmet>
         <section className="hero-section ptb-120 position-relative overflow-hidden bg11">
             <div className="container">
                 <div className="row justify-content-center text-center">
                     <div className="col-xl-8 col-lg-10 mb-5">
                         <div className="hero-content-wrap">
                             <h1 className="fw-bold display-5" data-aos="fade-up">We Desighed The Best UI/UX For Your Website,Web & Mobile Application</h1>
                             <p className="lead" data-aos="fade-up" data-aos-delay="50">
                             UI/UX design for web and mobile involves creating visually appealing and user-friendly interfaces for both web-based and mobile applications, ensuring a consistent and seamless user experience.
                             </p>
                             <div className="action-btns text-center pt-4" data-aos="fade-up" data-aos-delay="100">
                                 <Link to={contact} className="btn btn-primary me-lg-3 me-sm-3">Contact Us</Link>
                             </div>
                         </div>
                     </div>

                     <div className="col-lg-9">
                         <div className="position-relative" data-aos="fade-up" data-aos-delay="200">
                             <ul className="position-absolute animate-element parallax-element widget-img-wrap z-2">
                                 <li className="layer" data-depth="0.04">
                                     <img src={`${url}assets/img/screen/widget-3.png`} alt="widget-img"
                                          className="img-fluid widget-img-1 position-absolute shadow-lg rounded-custom"/>
                                 </li>
                                 <li className="layer" data-depth="0.02">
                                     <img src={`${url}assets/img/screen/widget-4.png`} alt="widget-img"
                                          className="img-fluid widget-img-3 position-absolute shadow-lg rounded-custom"/>
                                 </li>
                             </ul>
                             <img src={`${url}assets/img/dashboard-img.png`} alt="dashboard image"
                                  className="img-fluid position-relative rounded-custom mt-lg-5"/>
                         </div>
                     </div>
                 </div>
             </div>
             <div className="bg-dark position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z--1 py-5"></div>
         </section>

         <section className="customer-review pb-120 bg-dark">
             <div className="container">
                 <div className="row">
                     <div className="section-heading text-center">
                         <h2 className="fw-medium h4">Rated 5 out of 5 stars by our customers</h2>
                     </div>
                 </div>
                 <div className="row">
                     <div className="col-lg-4 col-md-4">
                         <div
                             className="review-info-wrap text-center rounded-custom d-flex flex-column h-100 p-lg-5 p-4">
                             <img src={`${url}assets/img/fb-logo-w.svg`} width="130" alt="facebook"
                                  className="img-fluid m-auto" />
                                 <div className="review-info-content mt-2">
                                     <p className="mb-4">4.9 out of 5 stars maintainable disseminate parallel team
                                         effective standards communities.</p>
                                 </div>
                                 {/* <a href="client-review.html"
                                    className="link-with-icon p-0 mt-auto text-decoration-none text-warning">Read
                                     Reviews <i
                                         className="far fa-arrow-right"></i></a> */}
                         </div>
                     </div>
                     <div className="col-lg-4 col-md-4">
                         <div
                             className="review-info-wrap text-center rounded-custom d-flex flex-column h-100 p-lg-5 p-4">
                             <img src={`${url}assets/img/g-logo-w.svg`} width="130" alt="google" className="img-fluid m-auto" />
                                 <div className="review-info-content mt-2">
                                     <p className="mb-4">2k+ five star reviews excellent convergence without
                                         professional methods of empowerment. </p>
                                 </div>
                                 {/* <a href="client-review.html"
                                    className="link-with-icon p-0 mt-auto text-decoration-none text-warning">Read
                                     Reviews <i
                                         className="far fa-arrow-right"></i></a> */}
                         </div>
                     </div>
                     <div className="col-lg-4 col-md-4">
                         <div
                             className="review-info-wrap text-center rounded-custom d-flex flex-column h-100 p-lg-5 p-4">
                             <img src={`${url}assets/img/li-logo-w.svg`} width="130" alt="linkedIn"
                                  className="img-fluid m-auto" />
                                 <div className="review-info-content mt-2">
                                     <p className="mb-4">4.9 out of 5 stars maintainable disseminate parallel team
                                         effective standards communities.</p>
                                 </div>
                                 {/* <a href="client-review.html"
                                    className="link-with-icon p-0 mt-auto text-decoration-none text-warning">Read
                                     Reviews <i
                                         className="far fa-arrow-right"></i></a> */}
                         </div>
                     </div>
                 </div>
             </div>
         </section>

         <section className="image-feature ptb-120">
             <div className="container">
                 <div className="row justify-content-between align-items-center">
                     <div className="col-lg-5 col-12">
                         <div className="feature-img-content">
                             <div className="section-heading" data-aos="fade-up">
                                 <h2>Browse our world class features</h2>
                                 <p>
                                 The UI (User Interface) design of a web page encompasses the visual elements like layout, color palette, typography, and imagery, while the UX (User Experience) design is concerned with the user's interaction with the website, including navigation, content arrangement, and overall functionality.                                 </p>
                             </div>
                             <ul className="list-unstyled d-flex flex-wrap list-two-col mb-0" data-aos="fade-up"
                                 data-aos-delay="50">
                                 <li>
                                     <div className="icon-box">
                                         <i className="fas fa-computer-classic text-white bg-primary rounded"></i>
                                     </div>
                                     <h3 className="h5">Web UI/UX</h3>
                                 </li>
                                 <li>
                                     <div className="icon-box">
                                         <i className="fas fa-mobile text-white bg-danger rounded"></i>
                                     </div>
                                     <h3 className="h5">Mobile UI/UX</h3>
                                 </li>
                             </ul>
                         </div>
                     </div>
                     <div className="col-lg-6 col-12">
                         <div className="feature-img-holder p-lg-5 pt-3">
                             <div
                                 className="p-lg-5 p-3 bg-danger-soft position-relative rounded-custom d-block feature-img-wrap">
                                 <div className="position-relative">
                                     <img src={`${url}assets/img/screen/widget-5.png`}
                                          className="img-fluid rounded-custom position-relative" alt="feature-image"
                                          data-aos="fade-up" data-aos-delay="50"/>
                                     <img src={`${url}assets/img/screen/widget-3.png`}
                                          className="img-fluid rounded-custom shadow position-absolute bottom--100 right--100 hide-medium"
                                          alt="feature-image" data-aos="fade-up" data-aos-delay="100"/>
                                 </div>
                                 <div
                                     className="position-absolute bg-dark-soft z--1 dot-mask dm-size-12 dm-wh-250 top-left"></div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </section>

         <section className="crm-service-section bg-white ptb-60 erpBOX">
             <div className="container">
                 <div className="row justify-content-center">
                     <div className="col-xl-6 col-lg-7">
                         <div className="crm-title text-center">
                                <span className="crm-subtitle">Our Services
                                    <img src={`${url}assets/img/shape/arrow-red.png`} alt="arrow" className="ms-2"/>
                                </span>
                             <h2 className="mt-1 mb-3">Web Design Services</h2>
                             <p className="mb-0">
                             Are you ready to leave a lasting impression on the internet? Whether you're seeking a fresh design for a custom website or need a stunning design for a popular CMS platform, our design services offer the ideal solution for your web design needs.
                                </p>
                         </div>
                     </div>
                 </div>
                 <div className="mt-5">
                     <div className="row align-items-center pt-5 pb-5">
                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-website-design-0f1a67d8d9.svg`}/>
                                     </div>
                                     <h3 className="h5">Web Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-wordpress-design-a6d6c00cbc.svg`}/>
                                     </div>
                                     <h3 className="h5">Wordpress Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-app-design-27fb7b89e6.svg`}/>
                                     </div>
                                     <h3 className="h5">App Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-icon-design-f552ce7d96.svg`}/>
                                     </div>
                                     <h3 className="h5">Icon Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-bigcommerce-714b49ec7b.svg`}/>
                                     </div>
                                     <h3 className="h5">BigCommerce Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-email-marketing-design-83265797fb.svg`}/>
                                     </div>
                                     <h3 className="h5">Email Marketing Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-newsletter-design-6ec104d146.svg`}/>
                                     </div>
                                     <h3 className="h5">Newsletter Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-shopify-design-95cca9b475.svg`}/>
                                     </div>
                                     <h3 className="h5">Shopify Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-squarespace-design-9b915c1620.svg`}/>
                                     </div>
                                     <h3 className="h5">Squarespace Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-wix-design-8ccb047ac7.svg`}/>
                                     </div>
                                     <h3 className="h5">Wix Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-wooCommerce-design-9818e6335e.svg`}/>
                                     </div>
                                     <h3 className="h5">WooCommerce Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>

                         <div className="col-lg-3 col-md-4 seo">
                             <div className="position-relative">
                                 <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                     <div
                                         className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                         <img src={`${url}assets/img/design-categories-landing-page-design-269a5940f7.svg`}/>
                                     </div>
                                     <h3 className="h5">Landing Page Design</h3>
                                 </div>
                                 <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                     <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
         </section>

     </>
    )
}
export default UIUXDDesigning
