import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {IntigrationLIst, intigrations} from "../constants/helpers";
import {Link} from "react-router-dom";
import IntigrationList from "../components/IntigrationList";
import {Helmet} from "react-helmet";
import {WebSerices} from "../constants/helpers";
import {contact,url,SetAppointmentUrl} from "../constants/helpers";
const WebSiteDevelopment = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let data = WebSerices.find(el => el.id === "3");
    return(
        <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
                <link rel= "canonical" href= "https://www.xampra.com/Web-Site-Development" />
            </Helmet>
            <section className="hero-section ptb-120 bg13">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-xl-5 col-lg-5">
                            <div className="hero-content-wrap text-center text-xl-start text-lg-start"
                                 data-aos="fade-right">
                                <h1 className="fw-bold display-5">We Develop Best Website for Your Business</h1>
                                <p className="lead">
                                Website development encompasses the comprehensive process of crafting and sustaining websites, which entails the artistic design, coding, and content creation necessary to establish a fully functional and visually captivating online presence.
                                </p>
                                <div className="action-btns mt-5">
                                    <Link to={"../"+SetAppointmentUrl} className="btn btn-primary">Book an Appointment</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 mt-4 mt-xl-0">
                            <div className="hero-img-wrap position-relative" data-aos="fade-left">
                                <ul className="position-absolute animate-element parallax-element shape-service hide-medium">
                                    <li className="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/color-shape/image-1.svg`} alt="shape"
                                             className="img-fluid position-absolute color-shape-1"/>
                                    </li>
                                    <li className="layer" data-depth="0.02">
                                        <img src={`${url}assets/img/color-shape/feature-2.svg`} alt="shape"
                                             className="img-fluid position-absolute color-shape-2 z-5"/>
                                    </li>
                                    <li className="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/color-shape/feature-3.svg`} alt="shape"
                                             className="img-fluid position-absolute color-shape-3"/>
                                    </li>
                                </ul>

                                <div className="hero-img-wrap position-relative">
                                    <div className="hero-screen-wrap">
                                        <div className="phone-screen">
                                            <img src={`${url}assets/img/phone-screen.png`} alt="hero image"
                                                 className="position-relative img-fluid"/>
                                        </div>
                                        <div className="mac-screen">
                                            <img src={`${url}assets/img/mac-screen.png`} alt="hero image"
                                                 className="position-relative img-fluid rounded-custom" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work-process ptb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-heading text-center" data-aos="fade-up">
                                <h4 className="h5 text-primary">Process</h4>
                                <h2>We Follow Our Work Process</h2>
                                <p>Proactively harness cross-media leadership skills to foster innovative and immersive experiences. Drive the development of vertical systems with intuitive architectures, fueling enhanced user engagement and satisfaction.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-5 col-md-12 order-1 order-lg-0">
                            <div className="img-wrap" data-aos="fade-up" data-aos-delay="50">
                                <img src={`${url}assets/img/implementation.png`} alt="work process"
                                     className="img-fluid rounded-custom" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 order-0 order-lg-1">
                            <ul className="work-process-list list-unstyled">
                                <li className="d-flex align-items-start mb-4" data-aos="fade-up" data-aos-delay="50">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src={`${url}assets/img/implementation.svg`}/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 1</span>
                                        <h3 className="h5 mb-2">Full-cycle website design and development</h3>
                                        <p>
                                        Crafting a Website from the Ground Up: From Gathering Requirements to Design, Implementation, Quality Assurance, and Ongoing Maintenance and Support.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4" data-aos="fade-up" data-aos-delay="100">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src={`${url}assets/img/redesign.svg`}/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 2</span>
                                        <h3 className="h5 mb-2">Redesign</h3>
                                        <p>
                                        Seamless Migration of Your Legacy Website: Transferring Data to a Modern Solution with an Intuitive and Dynamic User Interface.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4" data-aos="fade-up" data-aos-delay="150">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src={`${url}assets/img/integration.svg`}/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 3</span>
                                        <h3 className="h5 mb-2">Web application development and integration</h3>
                                        <p>
                                        Enhance User Engagement: Empower Your Website with Tailor-Made Social Networking Apps, Secure Payment Solutions, Advanced Analytics, and More.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4 mb-lg-0" data-aos="fade-up"
                                    data-aos-delay="200">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src={`${url}assets/img/migration-to-cloud.svg`}/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 4</span>
                                        <h3 className="h5 mb-2">Migration to cloud</h3>
                                        <p>
                                        Effortlessly Transfer Your Website and Applications to Leading Cloud Platforms like AWS, Azure, and Google Cloud for Enhanced Scalability, Simplified Administration, and Cost Optimization.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4 mb-lg-0" data-aos="fade-up"
                                    data-aos-delay="200">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src={`${url}assets/img/support.svg`}/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 5</span>
                                        <h3 className="h5 mb-2">Maintenance and support</h3>
                                        <p>
                                         Elevating Website Performance and User Experience: Innovating New Features, Resolving Bugs, Enhancing Scalability, and Optimizing SEO Compliance to Drive User Engagement, Data Security, and Business Growth.
                                        </p>
                                    </div>
                                </li>
                                <li className="d-flex align-items-start mb-4 mb-lg-0" data-aos="fade-up"
                                    data-aos-delay="200">
                                    <div className="process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2">
                                        <img src={`${url}assets/img/mobile-first-website.svg`}/>
                                    </div>
                                    <div className="icon-content">
                                        <span className="text-primary h6">Step 5</span>
                                        <h3 className="h5 mb-2">Mobile-driven development</h3>
                                        <p>
                                        Responsive Web Design for Seamless Mobile Experience: Optimizing Your Website for All Devices and Platforms, Serving as a Powerful Back-End Solution for Mobile Applications.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>



            <section className="promo-section ptb-120 bg-light ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-6">
                            <div className="section-heading text-center" data-aos="fade-up">
                                <h2>Our Customers Get Results</h2>
                                <p>Progressively deploy market positioning catalysts for change and technically sound.
                                    Authoritatively with backward-compatible
                                    e-services. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                            <div className="bg-dark2 text-white p-5 text-center d-flex flex-column h-100 rounded-custom"
                                 data-aos="fade-up" data-aos-delay="100">
                                <div className="promo-card-info mb-4">
                                    <h3 className="display-5 text-white fw-bold mb-4"><i
                                        className="far fa-fingerprint text-warning me-2"></i> 10x
                                    </h3>
                                    <p>Embrace distinctive best practices after B2B syndicate backend internal or
                                        whereas edge
                                        process improvements. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0 mt-md-0">
                            <div className="bg-dark2 text-white p-5 text-center d-flex flex-column h-100 rounded-custom"
                                 data-aos="fade-up" data-aos-delay="150">
                                <div className="promo-card-info mb-4">
                                    <h3 className="display-5 text-white fw-bold mb-4"><i
                                        className="far fa-paper-plane text-warning me-2"></i> 5k
                                    </h3>
                                    <p>Rapidiously embrace distinctive best practices B2B syndicate backend internal or
                                        whereas
                                        process improvements. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 mt-4 mt-lg-0">
                            <div className="bg-dark2 text-white p-5 text-center d-flex flex-column h-100 rounded-custom"
                                 data-aos="fade-up" data-aos-delay="200">
                                <div className="promo-card-info mb-4">
                                    <h3 className="display-5 text-white fw-bold mb-4"><i
                                        className="far fa-chart-pie-alt text-warning me-2"></i>
                                        95%</h3>
                                    <p>Distinctive best practices after B2B syndicate internal or whereas bleeding-edge
                                        process
                                        improvements. </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="feature-section-two ptb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between justify-content-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="feature-content-wrap">
                            <div className="section-heading">
                                    <h2>We are development experts on all technologies</h2>
                                    <p>At Xampra, We Possess Expertise in a Wide Range of Technologies, Allowing Us to Swiftly Incubate Functional Channels with Multidisciplinary Architectures and Authoritatively Formulate Exceptional Innovations.</p>
                                </div>
                                <ul className="list-unstyled mt-5">
                                    <li className="d-flex align-items-start mb-4">
                                        <div className="icon-box bg-primary rounded me-4">
                                            <i className="fas fa-bezier-curve text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">The Art of Pixel-Perfect Design</h3>
                                            <p>
                                            In the realm of web design, graphic design, and user interface design, the objective of pixel-perfect design is to achieve a visually flawless and exacting visual composition. Every element is meticulously positioned and aligned to a specific grid, ensuring a level of precision that enhances the overall aesthetic and user experience.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-start mb-4">
                                        <div className="icon-box bg-danger rounded me-4">
                                            <i className="fas fa-fingerprint text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">UCrafting Clean and Impactful Designs</h3>
                                            <p>
                                            In this design approach, the emphasis lies in the removal of unnecessary elements, utilizing a minimal color palette, and incorporating clean and simple lines. The goal is to create a design that is visually striking yet uncluttered, allowing for easy comprehension. By prioritizing simplicity and visual impact, designers achieve a harmonious balance between elegance and clarity.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-start mb-4">
                                        <div className="icon-box bg-dark rounded me-4">
                                            <i className="fas fa-cog text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">Embracing Diverse Perspectives for Market Expansion</h3>
                                            <p>
                                                In the pursuit of market expansion, our approach is to facilitate seamless transitions and foster prospective collaborations, transcending focused niche markets. By embracing diverse perspectives and encouraging idea-sharing, we create a dynamic ecosystem that thrives on innovation and collective growth. Our goal is to break down barriers and explore new possibilities, paving the way for a broader reach and sustained success.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7">
                            <div className="feature-img-wrap">
                                <img src={`${url}assets/img/feature-img3.png`} alt="feature image" className="img-fluid rounded-custom"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <IntigrationList/>
            
        </>
    )
}
export default WebSiteDevelopment
