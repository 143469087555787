import React from "react";
import "./App.css"
import $ from 'jquery';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import {
    home, about, contact, uIUXDDesigning, webAppDevelopment, webSiteDevelopment, cRMDevelopment,SetAppointmentUrl,
    intigrations, mobileAppDevelopment, digitalMarketing, eRPDevelopment, sEO, graphicDesigning, contentCreation, allServices, termsConditions, blog,url
} from "./constants/helpers";
import UIUXDDesigning from './pages/UIUXDDesigning';
import WebAppDevelopment from "./pages/WebAppDevelopment";
import WebSiteDevelopment from './pages/WebSiteDevelopment'
import CRMDevelopment from './pages/CRMDevelopment';
import Intigrations from './pages/Intigrations';
import MobileAppDevelopment from "./pages/MobileAppDevelopment";
import Contact from "./pages/Contact";
import DigitalMarketing from "./pages/DigitalMarketing";
import ERPDevelopment from "./pages/ERPDevelopment"
import SEO from "./pages/SEO"
import GraphicDesigning from "./pages/GraphicDesigning"
import ContentCreation from "./pages/ContentCreation";
import Services from "./pages/Services";
import ContactSupport from "./components/ContactSupport";
import TermsConditions from "./pages/TermsCondition";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import SetAppointment from "./pages/SetAppointment";
import {Helmet} from "react-helmet";
import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon,
  } from "react-share";
function App() {
    const openForm = () => {
        console.log("l")
        document.getElementById("myForm").style.display = "block";
      }
      function closeForm() {
        document.getElementById("myForm").style.display = "none";
      }
    return (
        <>
            <Header />
            <div className="main-wrapper">
                <Routes>
                    <Route path={home} element={<Home />} />
                    <Route path={about} element={<About />} />
                    <Route path={contact} element={<Contact />} />
                    <Route path={uIUXDDesigning} element={<UIUXDDesigning />} />
                    <Route path={webAppDevelopment} element={<WebAppDevelopment />} />
                    <Route path={webSiteDevelopment} element={<WebSiteDevelopment />} />
                    <Route path={cRMDevelopment} element={<CRMDevelopment />} />
                    <Route path={intigrations} element={<Intigrations />} />
                    <Route path={mobileAppDevelopment} element={<MobileAppDevelopment />} />
                    <Route path={digitalMarketing} element={<DigitalMarketing />} />
                    <Route path={eRPDevelopment} element={<ERPDevelopment />} />
                    <Route path={sEO} element={<SEO />} />
                    <Route path={graphicDesigning} element={<GraphicDesigning />} />
                    <Route path={contentCreation} element={<ContentCreation />} />
                    <Route path={allServices} element={<Services />} />
                    <Route path={termsConditions} element={<TermsConditions />} />
                    <Route path={SetAppointmentUrl} element={<SetAppointment />} />
                    <Route path={blog} element={<Blog />} />
                    <Route path="Blogs/blogDetail/:name" element={<BlogDetail />} />

                </Routes>
            </div>

            <ContactSupport />
            <button className="open-button" onClick={openForm}><i className="fa-solid fa-share-nodes"></i></button>
                <div className="chat-popup1" id="myForm">
                    <form action="" className="form-container1" style={{textAlign:"center"}}>
                        <a href="https://www.fiverr.com/abulhanan786" target="_blank"> <img src={`${url}Untitled-1.png`} style={{width:"42px"}}/> </a><br/>
                        <a href="https://www.upwork.com/freelancers/~01dec8ca06a77bac60" target="_blank"> <img src={`${url}up.png`} style={{width:"42px"}}/> </a><br/>
                        <a href="mailto:info@xampra.com" target="_blank"><EmailIcon size={42} round={true} /></a><br/>
                        <a href="https://twitter.com/Xampraoffical" target="_blank"><TwitterIcon size={42} round={true} /></a><br/>
                        <a href="https://www.facebook.com/xampraofficial/" target="_blank"><FacebookIcon size={42} round={true} /></a><br/>
                        <a href="https://www.pinterest.com/xampras/" target="_blank"><PinterestIcon size={42} round={true} /></a><br/>
                        <a href="https://www.linkedin.com/company/xampraoffical/" target="_blank"><LinkedinIcon size={42} round={true} /></a><br/>
                        <a href="https://www.reddit.com/user/Xamprasoftwarehouse" target="_blank"><RedditIcon size={42} round={true} /></a><br/>
                        <a href="https://www.instagram.com/xampraoffical/" className="insta" target="_blank"><i className="fa-brands fa-instagram"></i></a><br/>
                       
                        
                        <button type="button" className="cancel" onClick={closeForm}><i className="fa-regular fa-circle-xmark"></i></button>
                    </form>
                </div>
            <Footer />
        </>
    );
}

export default App;
