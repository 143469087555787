import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import renderHTML from 'react-render-html';

const BlogDetail = () => {
    const { name } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0)
        getBlogs();
    }, [])
    const [desc,setdesc]=useState("");
    const [res, setRes] = useState(null)
    const [blog, SetNewBlog] = useState()

    const getBlogs = async () => {
        await axios.get(`https://backend.xampra.com/backend/blogDetail.php?name=${name}`).then(response => {
            let d = response.data;
            setdesc(d.blogDesc)
            SetNewBlog(d);
        }).catch(err => {
            if (err) {
                setRes(err.response.data)
            }
        });
    }
    return (
        <>
            <Helmet>
                <title>{blog?.blogMetaTitle}</title>
                <meta name="description" content={blog?.blogMetaDesc} />
                <meta name="keywords" content={blog?.blogMetaKeyword} />
                <meta name="author" content="Abdul Hanan" />
                <link rel= "canonical" href={`https://www.xampra.com/${name}`} />
                
            </Helmet>
            <section className="page-header position-relative overflow-hidden ptb-120 bg-dark bg10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <h1 className="display-5 fw-bold">Blog Detail</h1>
                        </div>
                    </div>
                    <div className="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"></div>
                </div>
            </section>
            <section className="contact-promo ptb-120">
                <div className="container">
                    <div className="row justify-content-center">

                        <div className="col-lg-8 col-md-8 mt-4 mt-lg-0">
                            <div className="contact-us-promo p-5 bg-white rounded-custom custom-shadow d-flex flex-column h-100">
                                <img src={`https://backend.xampra.com/backend/uploads/${blog?.blogImg}`} style={{ width: "100%" }} />
                                <div className="contact-promo-info mb-1 mt-4">
                                    <h5>{blog?.blogName}</h5>
                                    <p>
                                        {renderHTML(desc)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogDetail;
