import React,{useEffect} from "react";
import {contact} from "../constants/helpers";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import {DigitalServices,url,SetAppointmentUrl} from "../constants/helpers";
const GraphicDesigning = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let data = DigitalServices.find(el => el.id === "4");
    return(
        <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
                <link rel= "canonical" href= "https://www.xampra.com/GraphicDesigning" />
            </Helmet>
            <section className="mk-hero-section bg-white position-relative overflow-hidden bg21">
                <span className="mk-hero-rectangle-shape position-absolute"></span>
                <img src={`${url}assets/img/shape/mk-hero-circle-line.png`} alt="circle line"
                     className="position-absolute start-0 w-100 mk-hero-circle-line"/>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-7">
                            <div className="mk-title">
                                <h1 className="display-4 fw-bold mk-title text-white">Graphic Designing</h1>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="mk-hero-content">
                                <p className="mb-4 text-white">
                                At Xampra, the possibilities for design are limitless. Whether you need a captivating logo or eye-catching flyers, our team of skilled designers from around the world has the talent and expertise to bring your vision to life.
                                </p>
                                <div className="d-flex align-items-center mk-btn-group flex-wrap">
                                    <Link to={"../"+SetAppointmentUrl} className="ins-btn mk-white-btn">Get Started</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div
                                className="mk-hero-dashboard text-md-center position-relative mt-60 position-relative">
                                <span className="mk-gradient-hero-shape position-absolute rounded-circle"></span>
                                <span
                                    className="mk-secondary-gradient-shape position-absolute rounded-circle"></span>
                                <img src={`${url}assets/img/shape/mk-doted.png`} alt="doted"
                                     className="mk-hero-doted position-absolute"/>
                                <img src={`${url}assets/img/grp.png`} alt="not found"
                                     className="img-fluid"/>
                                <img src={`${url}assets/img/marketing/dashboard-sm.png`} alt="dashboard"
                                     className="dashboard-sm d-none d-sm-block"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="mk-feature-section pt-5 pt-lg-0 pb-40 bg-white">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5">
                            <div className="mk-title text-center">
                                <span className="mk-subtitle fw-bold">Core Features</span>
                                <h2 className="mt-3 mb-0 mk-heading">We provide Quality works</h2>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xl-7 col-lg-7">
                                <div className="mk-feature-content">
                                    <h3 className="mk-heading mb-3 lh-lg">Save Time by Havening Everythings in one
                                        Platform</h3>
                                    <p className="mb-30">
                                    Graphic design is the process of creating visual content using design elements like typography and images to convey a message to a specific audience. The aim is to make the design visually appealing, effective and engaging. Designers use different tools and techniques to create designs for various purposes including branding, advertising, and web design.
                                    </p>
                                    <ul className="list-unstyled mb-0">
                                        <li className="py-1"><i
                                            className="fad fa-check-circle me-2 text-primary"></i>
                                            Logo & Branding
                                        </li>
                                        <li className="py-1"><i
                                            className="fad fa-check-circle me-2 text-primary"></i>
                                            Web Design Services
                                        </li>
                                        <li className="py-1"><i
                                            className="fad fa-check-circle me-2 text-primary"></i>
                                            Print Design Services</li>
                                        <li className="py-1"><i
                                            className="fad fa-check-circle me-2 text-primary"></i>
                                            Graphic Design Services
                                        </li>
                                        <li className="py-1"><i
                                            className="fad fa-check-circle me-2 text-primary"></i>
                                            Product & Merchandise Design Services
                                        </li>
                                        <li className="py-1"><i
                                            className="fad fa-check-circle me-2 text-primary"></i>
                                            Art & Illustration
                                        </li>
                                        <li className="py-1"><i
                                            className="fad fa-check-circle me-2 text-primary"></i>
                                            Get a better design for less</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="mk-feature-dashboard position-relative mt-5 mt-xl-0">
                                    <img src={`${url}assets/img/shape/mk-doted.png`} alt="doted"
                                         className="position-absolute mk-feature-dot"/>
                                    <img src={`${url}assets/img/gp2.png`} alt="dashboard"
                                         className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="crm-service-section bg-white ptb-60 erpBOX">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7">
                            <div className="crm-title text-center">
                                <span className="crm-subtitle">Our Services
                                    <img src={`${url}assets/img/shape/arrow-red.png`} alt="arrow" className="ms-2"/>
                                </span>
                                <h2 className="mt-1 mb-3">Logo & Branding</h2>
                                <p className="mb-0">
                                Looking to create a memorable brand identity? Achieve the perfect blend of colors, shapes, and words that truly represent your brand. Our services offer the ideal solution to design a captivating logo and ensure that your branding makes a lasting impact.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="row align-items-center pt-5 pb-5">
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-logo-design-386041ef44.svg`}/>
                                        </div>
                                        <h3 className="h5">Logo Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-businesscard-design-347292f1f1.svg`}/>
                                        </div>
                                        <h3 className="h5">Business Card Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-stationery-design-76e5d97a66.svg`}/>
                                        </div>
                                        <h3 className="h5">Stationery Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-label-design-275e614d8d.svg`}/>
                                        </div>
                                        <h3 className="h5">Label Design </h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-letterhead-design-88218d96e0.svg`}/>
                                        </div>
                                        <h3 className="h5">Letterhead Design </h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-logo-businesscard-4984a7eab0.svg`}/>
                                        </div>
                                        <h3 className="h5">Logo and Business Card Design </h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-signage-design-c1412c32a4.svg`}/>
                                        </div>
                                        <h3 className="h5">Signage Design </h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-billboard-design-e07f14f1a9.svg`}/>
                                        </div>
                                        <h3 className="h5"> Billboard Design </h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-tradeshow-booth-design-13bc6ebd1d.svg`}/>
                                        </div>
                                        <h3 className="h5"> Trade Show Booth Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-car-wrap-design-c59d7e8860.svg`}/>
                                        </div>
                                        <h3 className="h5">Car Wrap Design </h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="crm-service-section bg-white ptb-60 erpBOX">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7">
                            <div className="crm-title text-center">
                                <span className="crm-subtitle">Our Services
                                    <img src={`${url}assets/img/shape/arrow-red.png`} alt="arrow" className="ms-2"/>
                                </span>
                                <h2 className="mt-1 mb-3">Web Design Services</h2>
                                <p className="mb-0">
                                    Are you ready to leave a lasting impression on the internet? Whether you're seeking a fresh design for a custom website or need a stunning design for a popular CMS platform, our design services offer the ideal solution for your web design needs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="row align-items-center pt-5 pb-5">
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-website-design-0f1a67d8d9.svg`}/>
                                        </div>
                                        <h3 className="h5">Web Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-wordpress-design-a6d6c00cbc.svg`}/>
                                        </div>
                                        <h3 className="h5">Wordpress Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-app-design-27fb7b89e6.svg`}/>
                                        </div>
                                        <h3 className="h5">App Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-icon-design-f552ce7d96.svg`}/>
                                        </div>
                                        <h3 className="h5">Icon Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-bigcommerce-714b49ec7b.svg`}/>
                                        </div>
                                        <h3 className="h5">BigCommerce Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-email-marketing-design-83265797fb.svg`}/>
                                        </div>
                                        <h3 className="h5">Email Marketing Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-newsletter-design-6ec104d146.svg`}/>
                                        </div>
                                        <h3 className="h5">Newsletter Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-shopify-design-95cca9b475.svg`}/>
                                        </div>
                                        <h3 className="h5">Shopify Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-squarespace-design-9b915c1620.svg`}/>
                                        </div>
                                        <h3 className="h5">Squarespace Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-wix-design-8ccb047ac7.svg`}/>
                                        </div>
                                        <h3 className="h5">Wix Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-wooCommerce-design-9818e6335e.svg`}/>
                                        </div>
                                        <h3 className="h5">WooCommerce Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-landing-page-design-269a5940f7.svg`}/>
                                        </div>
                                        <h3 className="h5">Landing Page Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="crm-service-section bg-white ptb-60 erpBOX">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7">
                            <div className="crm-title text-center">
                                <span className="crm-subtitle">Our Services
                                    <img src={`${url}assets/img/shape/arrow-red.png`} alt="arrow" className="ms-2"/>
                                </span>
                                <h2 className="mt-1 mb-3">Print Design Services</h2>
                                <p className="mb-0">
                                    Sending something to print can be so final! Don't make the mistake of having a mistake - get the design right the first time, whether it's a flyer, poster or sticker. Check out all our print design services.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="row align-items-center pt-5 pb-5">
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-flyer-design-20c57a785c.svg`}/>
                                        </div>
                                        <h3 className="h5">Flyer Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-postcard-design-69f1a2e7ac.svg`}/>
                                        </div>
                                        <h3 className="h5">Postcard Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-brochure-design-41dd3a3712.svg`}/>
                                        </div>
                                        <h3 className="h5">Brochure Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-icon-design-f552ce7d96.svg`}/>
                                        </div>
                                        <h3 className="h5">Icon Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-poster-design-4c2235e27e.svg`}/>
                                        </div>
                                        <h3 className="h5">Poster Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-email-marketing-design-83265797fb.svg`}/>
                                        </div>
                                        <h3 className="h5">Email Marketing Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-newsletter-design-6ec104d146.svg`}/>
                                        </div>
                                        <h3 className="h5">Newsletter Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-advertisement-design-3bf09fad61.svg`}/>
                                        </div>
                                        <h3 className="h5">Advertisement Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-catalogue-design-16391b125e.svg`}/>
                                        </div>
                                        <h3 className="h5">Catalogue Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-envelope-design-e449ee25ae.svg`}/>
                                        </div>
                                        <h3 className="h5">Envelope Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-magazine-design-f15d13134d.svg`}/>
                                        </div>
                                        <h3 className="h5">Magazine Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-menu-design-1205e813ff.svg`}/>
                                        </div>
                                        <h3 className="h5">Menu Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-newspaper-ad-design-884a8ae089.svg`}/>
                                        </div>
                                        <h3 className="h5">Newspaper Ad Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-print-design-a5442faebf.svg`}/>
                                        </div>
                                        <h3 className="h5">Newspaper Ad Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-print-design-a5442faebf (1).svg`}/>
                                        </div>
                                        <h3 className="h5">Print Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-sticker-design-02ae92a28b.svg`}/>
                                        </div>
                                        <h3 className="h5">Sticker Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-resume-design-b1aa5b01e6.svg`}/>
                                        </div>
                                        <h3 className="h5">Resume Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-word-template-design-5ae0333007.svg`}/>
                                        </div>
                                        <h3 className="h5">Word Template Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="crm-service-section bg-white ptb-60 erpBOX">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7">
                            <div className="crm-title text-center">
                                <span className="crm-subtitle">Our Services
                                    <img src={`${url}assets/img/shape/arrow-red.png`} alt="arrow" className="ms-2"/>
                                </span>
                                <h2 className="mt-1 mb-3">Graphic Design Services</h2>
                                <p className="mb-0">
                                    Really need some design inspiration? Someone who can use their creative skills to make a design that will really pop? These graphic design services may be just what you're looking for.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="row align-items-center pt-5 pb-5">
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-graphic-design-f4a2561d19.svg`}/>
                                        </div>
                                        <h3 className="h5">Graphic Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-powerpoint-design-9e6b4e659f.svg`}/>
                                        </div>
                                        <h3 className="h5">PowerPoint Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-vector-design-f83cdf5668.svg`}/>
                                        </div>
                                        <h3 className="h5">Vector Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-three-d-design-df955b3d78.svg`}/>
                                        </div>
                                        <h3 className="h5">3D Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-photoshop-design-8dd8703fbb.svg`}/>
                                        </div>
                                        <h3 className="h5">Photoshop Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-podcast-design-cec84d59ae.svg`}/>
                                        </div>
                                        <h3 className="h5">Podcast Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="crm-service-section bg-white ptb-60 erpBOX">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7">
                            <div className="crm-title text-center">
                                <span className="crm-subtitle">Our Services
                                    <img src={`${url}assets/img/shape/arrow-red.png`} alt="arrow" className="ms-2"/>
                                </span>
                                <h2 className="mt-1 mb-3">Product & Merchandise Design Services</h2>
                                <p className="mb-0">
                                    Need some walking advertising? A little promotional gear for the team manning the tradeshow booth? Get custom apparel, mugs or caps from our professional designers.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="row align-items-center pt-5 pb-5">
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-t-shirt-design-0337015c20.svg`}/>
                                        </div>
                                        <h3 className="h5">T-shirt Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-packaging-design-c7bd6d188c.svg`}/>
                                        </div>
                                        <h3 className="h5">Packaging Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-bag-and-tote-design-c1bec00d33.svg`}/>
                                        </div>
                                        <h3 className="h5">Bag and Tote Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-cup-and-mug-design-a64dd3ec45.svg`}/>
                                        </div>
                                        <h3 className="h5">Cup and Mug Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-apparel-design-79c0fdb037.svg`}/>
                                        </div>
                                        <h3 className="h5">Apparel Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-podcast-design-cec84d59ae.svg`}/>
                                        </div>
                                        <h3 className="h5">Podcast Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="crm-service-section bg-white ptb-60 erpBOX">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-7">
                            <div className="crm-title text-center">
                                <span className="crm-subtitle">Our Services
                                    <img src={`${url}assets/img/shape/arrow-red.png`} alt="arrow" className="ms-2"/>
                                </span>
                                <h2 className="mt-1 mb-3">Product & Merchandise Design Services</h2>
                                <p className="mb-0">
                                    Not everyone is an artist - but the designers at DesignCrowd definitely are. Whether you need a mascot designed for your sporting team or a piece of custom wall art you'll find what you need with these design services.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="row align-items-center pt-5 pb-5">

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-illustration-design-db41a7e445.svg`}/>
                                        </div>
                                        <h3 className="h5">Illustration Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-book-cover-design-deec417a60.svg`}/>
                                        </div>
                                        <h3 className="h5">Book Cover Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-art-design-42e7a88647 (1).svg`}/>
                                        </div>
                                        <h3 className="h5">Art Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-card-design-5325aec69e.svg`}/>
                                        </div>
                                        <h3 className="h5">Card Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-cd-cover-design-b24752e28f.svg`}/>
                                        </div>
                                        <h3 className="h5">CD Cover Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-greeting-card-design-11fd351ed5.svg`}/>
                                        </div>
                                        <h3 className="h5">Greeting Card Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
    
                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-invitation-design-dedc7c316e.svg`} />
                                        </div>
                                        <h3 className="h5">Invitation Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`}    alt="shape"/>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 seo">
                                <div className="position-relative">
                                    <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                        <div
                                            className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                            <img src={`${url}assets/img/design-categories-ebook-cover-design-32401d2b4f.svg`} />
                                        </div>
                                        <h3 className="h5">eBook Cover Design</h3>
                                    </div>
                                    <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                        <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="crm-integration bg-white pt-120 pb-80">
                <div className="container">
                    <div className="row justify-content-between align-items-center g-4">
                        <div className="col-lg-6">
                            <div className="crm-appss-circle">
                                <img src={`${url}assets/img/crm/apps.png`}  alt="apps" className="img-fluid"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="crm-integration-content">
                                <div className="crm-title">
                                    <span className="crm-subtitle">Integration Software <img
                                        src="assets/img/shape/arrow-red.png" alt="arrow" className="ms-1"/></span>
                                    <h2 className="mt-2 mb-3">Integrated with Quiety <br/> Used Software</h2>
                                    <p>Objectively strategize e-business e-tailers after an expanded array of
                                        communities. Conveniently aggregate integrated best practices whereas resource
                                        sucking results. Appropriately network collaborative portals without progressive
                                        channels.</p>
                                    <p>Bring in quality leads, nurture them, and turn them into happy, paying customers.
                                        Get a complete view of all customer data—personal details, all communication
                                        you've had with them, and more—all in a single place.</p>
                                </div>
                                <a href="#" className="btn crm-secondary-btn mt-4">Browse All Service</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
export default GraphicDesigning
