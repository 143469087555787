import React,{useEffect} from "react";
import {IntigrationLIst} from '../constants/helpers';
import {Helmet} from "react-helmet";
import {WebSerices} from "../constants/helpers";
const Intigrations = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let data = WebSerices.find(el => el.id === "6");
    return(
        <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
                <link rel= "canonical" href= "https://www.xampra.com/Integrations" />
            </Helmet>
            <div className="bg-dark1">
                <section className="page-header text-white position-relative overflow-hidden ptb-120 bg15">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <h1 className="display-5 text-white fw-bold">Integrations</h1>
                                <p className="lead">We Integrate your Application / Website With those API</p>
                            </div>
                        </div>
                        <div className="bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light right-5"></div>
                    </div>
                </section>
            </div>

            <section className="integration-section bg-light ptb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-lg-6 col-md-12">
                            <div className="section-heading">
                                <h5 className="h6 text-primary">Integration</h5>
                                <h2>Integration with Most Powerful Platforms</h2>
                                <p>
                                We combining multiple software applications or systems to work together as a single, seamless solution. This can involve exchanging data and information between the systems, or using APIs and other technology to coordinate their activities. The goal of integration is to improve efficiency, streamline workflows, and eliminate the need for manual data transfer, thereby reducing the risk of errors and increasing productivity.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="section-heading">
                                <p>
                                    
ChatGPT
Third-party integration involves connecting and combining software or services from external sources with an existing application or system. This integration facilitates the sharing of data, resources, and functionality between the two systems, enabling them to work together smoothly. It encompasses various integrations, such as incorporating payment gateways, integrating social media platforms, and linking with customer relationship management (CRM) systems.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="integration-wrapper position-relative w-100">
                                <ul className="integration-list list-unstyled mt-5">
                                    {IntigrationLIst.map((data,index)=>{
                                        return(
                                            <li key={index+data.id}>
                                                <div className="single-integration">
                                                    <img src={data.logo} alt="integration"
                                                         className="img-fluid"/>
                                                    <h6 className="mb-0 mt-4">{data.name}</h6>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Intigrations
