import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import {about, contact} from "../constants/helpers";
import {Helmet} from "react-helmet";
import {DigitalServices,url,SetAppointmentUrl} from "../constants/helpers";
const SEO = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let data = DigitalServices.find(el => el.id === "2");
    return(
        <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
                <link rel= "canonical" href= "https://www.xampra.com/SEO" />
            </Helmet>
            <section class="hero-section ptb-120 text-white bg-gradient bg22">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-10">
                            <div class="hero-content-wrap mt-5">
                                <h1 class="fw-bold display-5">Search Engine Optimization (SEO)</h1>
                                <p class="lead">
                                Our primary focus is on helping our clients generate revenue, and we offer a comprehensive range of services and advanced technology to support the growth of your business through effective SEO strategies.
                                </p>
                                <div class="action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
                                    <Link  to={"../"+SetAppointmentUrl} class="btn btn-danger me-3">Request For Meeting</Link>
                                </div>
                               
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-8 mt-5">
                            <div class="hero-img position-relative circle-shape-images">

                                <ul class="position-absolute animate-element parallax-element circle-shape-list">
                                    <li class="layer" data-depth="0.02">
                                        <img src={`${url}assets/img/shape/circle-1.svg`} alt="shape" class="circle-shape-item type-1 hero-1"/>
                                    </li>
                                    <li class="layer" data-depth="0.04">
                                        <img src={`${url}assets/img/shape/circle-1.svg`} alt="shape" class="circle-shape-item type-2 hero-1"/>
                                    </li>
                                    <li class="layer" data-depth="0.04">
                                        <img src={`${url}assets/img/shape/circle-1.svg`} alt="shape" class="circle-shape-item type-3 hero-1"/>
                                    </li>
                                    <li class="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/shape/circle-1.svg`} alt="shape" class="circle-shape-item type-4 hero-1"/>
                                    </li>
                                    <li class="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/shape/circle-1.svg`} alt="shape" class="circle-shape-item type-5 hero-1"/>
                                    </li>
                                </ul>
                                <img src={`${url}assets/img/seo.png`} alt="hero img" class="img-fluid position-relative z-5"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="why-choose-us pt-60 pb-120">
                <div className="container">
                    <div className="row justify-content-lg-between align-items-center">
                        <div className="col-lg-6 col-12 order-1 order-lg-0">
                            <div className="feature-img-holder mt-5 mt-lg-0 mt-xl-0">
                                <img src={`${url}assets/img/widget-7.png`} className="img-fluid" alt="feature-image"/>
                            </div>
                        </div>
                        <div className="col-lg-5 col-12">
                            <div className="why-choose-content">
                                <div className="icon-box rounded-custom bg-primary shadow-sm d-inline-block">
                                    <i className="fal fa-shield-check fa-2x text-white"></i>
                                </div>
                                <h2>What do our SEO management services include</h2>
                                <p>
                                Implementing SEO is a highly effective online marketing strategy that plays a crucial role in enhancing your business's online visibility, driving increased website traffic, reaching targeted and valuable leads, and ultimately boosting conversions and revenue. Therefore, SEO is an indispensable component of a thriving business model.
                                </p>
                                <p>
                                    However, SEO is more than just one strategy.
                                    <br/>
                                    Indeed, SEO encompasses various interconnected techniques that synergistically contribute to enhancing your Google ranking (or rankings on other search engines), ultimately transforming your website into a powerful conversion and revenue-generating platform.
                                </p>
                                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Website
                                        SEO audits
                                    </li>
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Keyword
                                        research
                                    </li>
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>Content
                                        creation and implementation
                                    </li>
                                    <li className="py-1"><i className="fas fa-check-circle me-2 text-primary"></i>On-page
                                        optimization
                                    </li>
                                </ul>
                                <Link to={about} className="read-more-link text-decoration-none">Know More About
                                    Us <i className="far fa-arrow-right ms-2"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="feature-section ptb-120 seo">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="section-heading text-center">
                                <h4 class="text-primary h5">Build Quality</h4>
                                <h2>Our Special Features</h2>
                                <p>
                                    Here’s a look at our SEO process, which has driven over 7.8 million leads and more than $3 billion in revenue for Xampera clients.
                                    Our R.O.C.K.E.T process includes six elements
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center pt-5 pb-5">
                        <div class="col-lg-4 col-md-6">
                            <div class="position-relative">
                                <div class="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                    <div class="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/icon-magnifying-glass.svg`} />
                                    </div>
                                    <h3 class="h5">Research</h3>
                                </div>

                                <div class="dot-shape-bg position-absolute z--1 left--40 top--40">
                                    <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="position-relative">
                                <div class="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0">
                                    <div class="feature-icon d-inline-block bg-danger-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/icon-rocketship.svg`}/>
                                    </div>
                                    <h3 class="h5">Optimize</h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="position-relative">
                                <div class="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0">
                                    <div class="feature-icon d-inline-block bg-success-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/icon-writing.svg`}/>
                                    </div>
                                    <h3 class="h5">Content</h3>
                                </div>

                                <div class="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                                    <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative">
                                <div
                                    className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                    <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/icon-search-engine.svg`}/>
                                    </div>
                                    <h3 className="h5">Keywords</h3>
                                </div>

                                <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                    <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative">
                                <div
                                    className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0">
                                    <div className="feature-icon d-inline-block bg-danger-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/icon-content-sharing.svg`}/>
                                    </div>
                                    <h3 className="h5">Earned media & links</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative">
                                <div
                                    className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0">
                                    <div className="feature-icon d-inline-block bg-success-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/icon-sales-funnel.svg`}/>
                                    </div>
                                    <h3 className="h5">Testing</h3>
                                </div>

                                <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                                    <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <section class="faq-section ptb-120 bg-light">
                <div class="container">
                    <div class="row align-items-center justify-content-between">
                        <div class="col-lg-12 col-12">
                            <div className="feature-grid">
                                <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                                    <div className="feature-content">
                                        <h3 className="h5">Research</h3>
                                        <p>
                                            When we kick-off an SEO campaign, your dedicated SEO consultant begins by
                                            doing a deep dive into your company, your website, your competitors and your
                                            industry.
                                        </p>
                                        <ul className="list-unstyled mb-0">
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>In-depth Competitor Analysis
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Site & Server Analysis
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Analyze 200+ On-Site SEO Factors
                                            </li>
                                        </ul>
                                        <p>
                                            We put on our detective hats and use data to put together a roadmap for your
                                            website’s SEO.
                                        </p>
                                    </div>
                                </div>

                                <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                                    <div className="feature-content">
                                        <h3 className="h5">Optimize</h3>
                                        <p>
                                            From IA to UX, we make hundreds of changes both big and small to your website to help Google better understand who you are.
                                        </p>
                                        <ul className="list-unstyled mb-0">
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Attention-grabbing titles & meta descriptions
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Update Site Architecture
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Analyze 200+ On-Site SEO Factors
                                            </li>
                                        </ul>
                                        <p>
                                            With a dedicated web development team, our SEO company will also take care of your technical SEO, helping you increase the usability, speed, and functionality of your website.
                                        </p>
                                    </div>
                                </div>
                                <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                                    <div className="feature-content">
                                        <h3 className="h5">Content</h3>
                                        <p>
                                            Your website content is what users count on to help educate and guide them through the purchasing funnel.
                                        </p>
                                        <ul className="list-unstyled mb-0">
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Website SEO Copy That Converts
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Longform Copy That Educates
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Informative Blog Posts That Intrigue
                                            </li>
                                        </ul>
                                        <p>
                                            Whether discussing your company’s unique selling point or providing educational content, we’ll provide topic suggestions, professional copy, and quality content to propel not only your site’s ranking but also your company’s growth.
                                        </p>
                                    </div>
                                </div>

                                <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                                    <div className="feature-content">
                                        <h3 className="h5">Keywords</h3>
                                        <p>
                                            Our meticulous keyword research process is at the heart of our SEO services. The specific keywords we target help us define our audience and drive valuable traffic to your site.
                                        </p>
                                        <ul className="list-unstyled mb-0">
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Competitor Keyword Analysis
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Keyword Mapping Report
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Advanced Rank Tracking
                                            </li>
                                        </ul>
                                        <p>
                                            With relevant traffic heading to your website, your company can secure fresh leads, high-value sales, and more.
                                        </p>
                                    </div>
                                </div>

                                <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                                    <div className="feature-content">
                                        <h3 className="h5">Earned media & links</h3>
                                        <p>
                                            Our meticulous keyword research process is at the heart of our SEO services. The specific keywords we target help us define our audience and drive valuable traffic to your site.
                                        </p>
                                        <ul className="list-unstyled mb-0">
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Content Promotion
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Relevant Mentions From Blogs/Press
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Viral Marketing Campaigns
                                            </li>
                                        </ul>
                                        <p>
                                            Our dedicated content marketing team will create new opportunities for website growth by helping your company build links (and relationships) with trusted experts in your industry.
                                        </p>
                                    </div>
                                </div>

                                <div className="feature-card border border-light border-2 bg-white rounded-custom p-5">
                                    <div className="feature-content">
                                        <h3 className="h5">Testing</h3>
                                        <p>
                                            Getting relevant, qualified search traffic to your site is just the beginning of our SEO optimization services.
                                        </p>
                                        <ul className="list-unstyled mb-0">
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Conversion Path Analysis
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Calls-to-action Improvements
                                            </li>
                                            <li className="py-1"><i
                                                className="fad fa-check-circle me-2 text-primary"></i>Continual Improvements Via ROI Tracking
                                            </li>
                                        </ul>
                                        <p>
                                            We believe in testing everything and making continual improvements to your SEO marketing strategy so your business can continue to drive revenue from SEO.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default SEO
