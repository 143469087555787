import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";
import {
    uIUXDDesigning,
    mobileAppDevelopment,
    webAppDevelopment,
    WebSerices,
    DigitalServices,
    intigrations, contact, about,url
} from "../constants/helpers";
import { Helmet } from "react-helmet";
import ContactSupport from "../components/ContactSupport";
const Home = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <>
            <Helmet>
                <title>Best IT service provider US and Canada | Xampra</title>
                <meta name="description" content="Xampra provide Mobile application, Website development, CRM development, SEO, Digital marketing, Graphic designing all over the world." />
                <meta name="keywords" content="Website Development, Web Application Development, Android App Development" />
                <meta name="author" content="Abdul Hanan" />
                <link rel= "canonical" href= "https://www.xampra.com/" />
            </Helmet>
            
            <section className="sc-hero-section ptb-120 position-relative overflow-hidden z-1">
            <img src={`${url}assets/img/software-company/hero-curve-line.png`} alt="curve line" className="position-absolute end-0 top-0 z--1"/>
            <img src={`${url}assets/img/software-company/section-line.png`} alt="section line" className="position-absolute z--1 start-50 top-0 translate-middle-x"/>
            <img src={`${url}assets/img/software-company/prism.png`} alt="prism" className="position-absolute prism z--1"/>
            <img src={`${url}assets/img/software-company/coin.png`} alt="coin" className="position-absolute coin z--1"/>
            <img src={`${url}assets/img/software-company/hero-bg-shape.webp`} alt="bg shape" className="position-absolute bottom-0 start-0 z-1 w-100"/>
            <img src={`${url}assets/img/software-company/hero-line.png`} alt="hero line" className="position-absolute hero-line bottom-0 translate-middle-x z-1"/>
            <span className="skew-1 position-absolute"></span>
            <span className="skew-2 position-absolute"></span>
            <div className="container">
                <div className="row g-4 align-items-center justify-content-between">
                    <div className="col-lg-6">
                        <div className="sc-hero-content">
                            <h1 className="mb-4 text-white display-5 fw-bold">Make Digital Business Very Easy Way!</h1>
                            <p className="mb-5 text-white">Xampra: Empowering Businesses with Tailored Software <br className="d-none d-xl-block"/>Solutions for Digital Transformation and Growth</p>
                            <a href="#Services" className="btn btn-primary">Explore our works</a>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="sc-hero-right position-relative mt-5 mt-lg-0">
                            <img src={`${url}assets/img/software-company/hero-img.webp`} alt="not found" className="img-fluid"/>
                            <img src={`${url}assets/img/software-company/circle-shape-lg.webp`} alt="shape" className="position-absolute z--1 w-100 hero-circle-shape"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
            {/* <section className="hero-it-solution hero-nine-bg ptb-120 bg1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                                <h1 className="fw-bold display-5">We Care Your any IT Solution</h1>
                                <p className="lead">
                                    Make your work easier with an integrated ecosystem that lets
                                    all departments work properly together.
                                </p>
                                <div
                                    className="action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
                                    <Link to={contact} className="btn btn-primary me-3">Request For Demo</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="hero-img position-relative mt-5 mt-lg-0">
                                <img src="assets/img/banner_image1.png" alt="hero hero-it-solution "
                                    className="img-fluid" />
                                <div className="dots">
                                    <img src="assets/img/banner_dot.png" alt="dot" className="dot-1" />
                                    <img src="assets/img/banner_dot.png" alt="dot" className="dot-2" />
                                </div>
                                <div className="bubble">
                                    <span className="bubble-1"></span>
                                    <span className="bubble-2"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className="ptb-120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-left text-lg-center mb-32 mb-lg-0">
                                <img src={`${url}assets/img/Why-chose-us-01.webp`} alt="" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-right">
                                <h4 className="text-primary h5 mb-3">Why Choose Us</h4>
                                <h2 className="mb-4">
                                8 Years of Software Excellence: Xampra - Your Partner for Innovative Solutions.
                                </h2>
                                <p>
                                Xampra: Fueling Technological Growth for Global Industry Leaders, Dynamic Startups, and Agile SMEs. With our expertise in bespoke software development, visionary product design, rigorous quality assurance, and strategic consultancy, we catalyze business innovation and empower organizations to transcend their limits, unlocking unparalleled value in today's digital landscape.
                                </p>
                                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                                    <li className="py-1">
                                        <div className="d-flex about-icon-box align-items-center">
                                            <div className="me-3">
                                                <img src={`${url}assets/img/pie-chart.png`} alt="" />
                                            </div>
                                            <div>
                                                <h5>Expert around the world</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="py-1">
                                        <div className="d-flex about-icon-box align-items-center">
                                            <div className="me-3">
                                                <img src={`${url}assets/img/team.png`} alt="" />
                                            </div>
                                            <div>
                                                <h5>Quick Support</h5>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <ul className="list-unstyled d-flex flex-wrap list-two-col mt-4 mb-4">
                                    <li className="py-1">
                                        <i className="fas fa-check-circle me-2 text-primary"></i>
                                        Web Application
                                    </li>
                                    <li className="py-1">
                                        <i className="fas fa-check-circle me-2 text-primary"></i>
                                        ERP Software
                                    </li>
                                    <li className="py-1">
                                        <i className="fas fa-check-circle me-2 text-primary"></i>
                                        UI/UX Design
                                    </li>
                                    <li className="py-1">
                                        <i className="fas fa-check-circle me-2 text-primary"></i>
                                        Mobile Application
                                    </li>
                                    <li className="py-1">
                                        <i className="fas fa-check-circle me-2 text-primary"></i>
                                        SEO
                                    </li>
                                    <li className="py-1">
                                        <i className="fas fa-check-circle me-2 text-primary"></i>
                                        CRM Software
                                    </li>
                                </ul>
                                <Link to={about} className="link-with-icon text-decoration-none mt-3 btn btn-primary">
                                Learn More
                                    <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ptb-120 bg-dark bg-dark1">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="text-center">
                                <h2>Our Popular Services</h2>
                                <p>
                                Enabling Technological Advancements through Innovative Engineering: At Xampra, we embody the essence of building technology, harnessing state-of-the-art techniques and practices. With our exceptional technical prowess, collaborative teams, deep market insights, and agile prototyping, we craft extraordinary software and hardware solutions. Our unwavering commitment lies in delivering uncompromising quality and reliability, paving the way for transformative technology experiences.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="service-tabs">
                                <ul className="nav nav-pills d-flex justify-content-center" id="pills-tab"
                                    role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active me-4" id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home" type="button" role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true">
                                            <i className="fas fa-desktop me-3"></i>
                                            <span>UX/UI Design</span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link me-4" id="pills-profile-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-profile" type="button" role="tab"
                                            aria-controls="pills-profile" aria-selected="false">
                                            <i className="fas fa-tablet-alt me-3"></i>
                                            <span>App Development</span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill"
                                            data-bs-target="#pills-contact" type="button" role="tab"
                                            aria-controls="pills-contact" aria-selected="false">
                                            <i className="fas fa-vector-square me-2"></i>
                                            <span>Web Applications</span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                aria-labelledby="pills-home-tab">
                                <div className="tab-content-wrapper pt-60">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="text-center mb-5 mb-lg-0">
                                                <img src={`${url}assets/img/tab_img1.webp`} alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="tab-right-content">
                                                <h2>
                                                Exquisite UI/UX Design: Elevating Your Website or Web App
                                                </h2>
                                                <p>
                                                Elevating Experiences: Unleashing Intuitive UI/UX Design for Web Success. Our meticulous approach crafts captivating aesthetics and seamless user experiences. By understanding your audience and business goals, we create an immersive solution that exceeds expectations                                                </p>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Web-Site Designing</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Mobile Application Designing</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Game UI/UX Designing</span>
                                                    </li>
                                                </ul>
                                                <Link to={uIUXDDesigning} className="text-white link-with-icon text-decoration-none">
                                                    Know More About Us
                                                    <i className="fas fa-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                                aria-labelledby="pills-contact-tab">
                                <div className="tab-content-wrapper pt-60">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="mb-5 mb-lg-0">
                                                <img src={`${url}assets/img/Untitled-1.webp`} alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="tab-right-content">
                                                <h2>Powerful Mobile Experiences Unleashed: Discover the Apps We Create</h2>
                                                <p>
                                                    Revolutionizing Mobile Experiences: Unleashing Next-Level iOS & Android Applications. Our mobile app development services redefine the boundaries of digital innovation, seamlessly complementing or surpassing web solutions. With a focus on user-centric design and cutting-edge technology, we create transformative mobile experiences that captivate and empower users.
                                                </p>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Mobile banking, billing apps, wallets</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Patient and hospital apps</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Messaging and VoIP apps</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>E-Commerce apps</span>
                                                    </li>
                                                </ul>
                                                <Link to={mobileAppDevelopment} className="text-white link-with-icon text-decoration-none">
                                                    Read More
                                                    <i className="fas fa-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div className="tab-content-wrapper pt-60">
                                    <div className="row align-items-center">
                                        <div className="col-md-6">
                                            <div className="pe-5 mb-5 mb-lg-0">
                                                <img src={`${url}assets/img/Untitled-2.webp`} alt="" className="img-fluid" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="tab-right-content">
                                                <h2>Web Solutions Perfected: Empowering Your Digital Journey</h2>
                                                <p>
                                                Unleashing the Power of Web: Redefining Digital Experiences with Innovative Applications. Web applications, the dynamic software programs accessed effortlessly through web browsers, harness the cutting-edge prowess of web technologies, all securely hosted on robust web servers. Offering the same feature-rich capabilities as desktop applications, they provide unparalleled accessibility and convenience. With a keen focus on user experience, fortified security, limitless scalability, and tailored targeting, we craft web solutions that seamlessly bridge the gap between technology and user needs, propelling businesses towards unrivaled success.
                                                </p>
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Enterprise Web Apps</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Web Portals</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Customer-facing Apps</span>
                                                    </li>
                                                    <li>
                                                        <i className="fas fa-check text-primary"></i>
                                                        <span>Ecommerce</span>
                                                    </li>
                                                </ul>
                                                <Link to={webAppDevelopment} className="text-white link-with-icon text-decoration-none">
                                                    Read More
                                                    <i className="fas fa-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="feature-promo ptb-120 bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-heading text-center">
                                <h2>Elevating Experiences with Essential Features</h2>
                                <p>Fueling Premier Ideas, Embracing Web-Ready Growth, Beyond Traditional Strategies, Unleashing Digital Potential.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-4 text-center mt-3">
                                <div className="feature-icon icon-center pb-5 rounded-custom bg-primary">
                                    <i className="fal fa-layer-group fa-2x text-white"></i>
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Good Performance</h3>
                                    <p>
                                    Software performance encompasses the speed, efficiency, and scalability of a system, crucial for ensuring user satisfaction and system reliability. Enhancing performance entails fine-tuning algorithms, minimizing resource consumption, and implementing parallel processing techniques to achieve optimal results.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-4 text-center mt-3">
                                <div className="feature-icon icon-center pb-5 rounded-custom bg-danger">
                                    <i className="fal fa-shield-check fa-2x text-white"></i>
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Highly Secure</h3>
                                    <p>
                                    Securing Valuable Data: Fortified Software Solutions. Our meticulously crafted software safeguards sensitive information, employing encryption, secure authentication, and strict access controls. Continuous testing and timely updates ensure the highest level of security, crucial for industries handling sensitive data.  
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature-promo p-lg-5 p-4 text-center mt-3">
                                <div className="feature-icon icon-center pb-5 rounded-custom bg-dark">
                                    <i className="fal fa-code fa-2x text-white"></i>
                                </div>
                                <div className="feature-info-wrap">
                                    <h3 className="h5">Fast Development</h3>
                                    <p>
                                        Fast development means creating, testing, and deploying software quickly using agile methodologies, modern tools, and cloud infrastructure. It improves time-to-market and allows for frequent releases. Requires collaboration, communication, and continuous integration and deployment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-lg-5 pt-3">
                        <div className="col-lg-6 mt-4">
                            <div
                                className="position-relative d-flex flex-column h-100 flex-wrap bg-danger-soft p-5 rounded-custom">
                                <div className="cta-left-info mb-2">
                                    <h5>Utilize your software data</h5>
                                    <p>
                                    Swift Software Development: Expediting Creation, Testing, and Deployment. By harnessing agile methodologies, cutting-edge tools, and cloud infrastructure, accelerated development slashes time-to-market and enables frequent releases. Seamless collaboration, effective communication, and continuous integration and deployment drive this dynamic process.
                                    </p>
                                </div>
                                <div className="cta-img position-absolute right-0 bottom-0">
                                    <img src={`${url}assets/img/cta-img-1.webp`} alt="cta img" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-4" >
                            <div
                                className="position-relative d-flex flex-column h-100 flex-wrap bg-primary-soft p-5 rounded-custom">
                                <div className="cta-left-info mb-2">
                                    <h5>Get real time updated anytime</h5>
                                    <p>
                                    Instant Access to Real-Time Updates: Harnessing Live Information Streams. By leveraging real-time data streams and event-driven architecture, users can receive information as soon as it becomes available, enabling immediate notifications and live updates. Ensuring effective real-time updates necessitates robust infrastructure, reliability, and scalability.
                                    </p>
                                </div>
                                <div className="cta-img position-absolute right-0 bottom-0">
                                    <img src={`${url}assets/img/cta-img-2.webp`} alt="cta img" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br id="Services"/>
            </section>
            
            <section className="services-icon ptb-120" >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-heading text-center">
                                <h2>Services We Provide</h2>
                                <p>
                                    Nurture innovative ideas that excel beyond traditional brick-and-mortar strategies, embracing the distributed potential of web-ready platforms.
                                </p>
                            </div>
                            ,
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        {WebSerices.map((data, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 p-0" key={index+1}>
                                    <div className="single-service homeser p-lg-5 p-4 text-center mt-3 border-bottom border-end">
                                        <Link to={data.link}>
                                            <div className="service-icon icon-center">
                                                <img src={url+data.logo} width={"60px"} />
                                            </div>
                                            <div className="service-info-wrap">
                                                <h3 className="h5">{data.name}</h3>
                                                <p>
                                                    {data.shortDesc}
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}

                        {DigitalServices.map((data, index) => {
                            return (
                                <div className="col-lg-4 col-md-6 p-0" key={index+1}>
                                    <div className="single-service p-lg-5 p-4 text-center mt-3 homeser border-bottom border-end">
                                        <Link to={data.link}>
                                            <div className="service-icon icon-center">
                                                <img src={url+data.logo} width={"60px"} />
                                            </div>
                                            <div className="service-info-wrap">
                                                <h3 className="h5">{data.name}</h3>
                                                <p>
                                                    {data.shortDesc}
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </section>

            <section className="integration-section pb-80">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-lg-3">
                            <div className="integration-list-wrap">
                                <a href="integration-single.html" className="integration-1" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src={`${url}assets/img/integations/1.png`} alt="integration"
                                        className="img-fluid rounded-circle" />
                                </a>
                                <a href="integration-single.html" className="integration-2" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src={`${url}assets/img/integations/2.png`} alt="integration"
                                        className="img-fluid rounded-circle" />
                                </a>
                                <a href="integration-single.html" className="integration-3" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src={`${url}assets/img/integations/3.png`} alt="integration"
                                        className="img-fluid rounded-circle" />
                                </a>

                                <a href="integration-single.html" className="integration-4" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src={`${url}assets/img/integations/4.png`} alt="integration"
                                        className="img-fluid rounded-circle" />
                                </a>
                                <a href="integration-single.html" className="integration-5" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src={`${url}assets/img/integations/5.png`} alt="integration"
                                        className="img-fluid rounded-circle" />
                                </a>
                                <Link to={intigrations} className="integration-6" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Your Brand Name">
                                    <img src={`${url}assets/img/integations/6.png`} alt="integration"
                                        className="img-fluid rounded-circle" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="section-heading text-center my-5 my-lg-0 my-xl-0">
                                <h4 className="text-primary h5">Integration</h4>
                                <h2>We Collaborate with Top Software Company</h2>
                                <Link to={intigrations} className="mt-4 btn btn-primary">View all Integration</Link>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="col-lg-4">
                                <div className="integration-list-wrap">
                                    <a href="integration-single.html" className="integration-7" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Your Brand Name">
                                        <img src={`${url}assets/img/integations/7.png`} alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>
                                    <a href="integration-single.html" className="integration-8" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Your Brand Name">
                                        <img src={`${url}assets/img/integations/8.png`} alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>
                                    <a href="integration-single.html" className="integration-9" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Your Brand Name">
                                        <img src={`${url}assets/img/integations/9.png`} alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>

                                    <a href="integration-single.html" className="integration-10"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                                        <img src={`${url}assets/img/integations/10.png`} alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>
                                    <a href="integration-single.html" className="integration-11"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                                        <img src={`${url}assets/img/integations/11.png`} alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>
                                    <a href="integration-single.html" className="integration-12"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Your Brand Name">
                                        <img src={`${url}assets/img/integations/12.png`} alt="integration"
                                            className="img-fluid rounded-circle" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
export default Home
