import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import {contact,url,SetAppointmentUrl} from "../constants/helpers";
import {Helmet} from "react-helmet";
import {DigitalServices} from "../constants/helpers";

const ContentCreation = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let data = DigitalServices.find(el => el.id === "5");
    return(
        <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
                <link rel= "canonical" href= "https://www.xampra.com/ContentCreation" />
            </Helmet>
            <section className="hero-section text-white hero-ten bg23">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                                <h1 className="fw-bold display-5 text-white">
                                Powerful Content Creation for Your Success
                                </h1>
                                <div className="action-btn mt-5 align-items-center d-block d-sm-flex d-lg-flex d-md-flex">
                                <Link to={"../"+SetAppointmentUrl} class="btn btn-primary me-3">Request For Meeting</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8 mt-5">
                            <div className="hero-img position-relative circle-shape-images">
                                <img src={`${url}assets/img/contentcreation.png`} alt="hero img" className="img-fluid position-relative z-5" />
                            </div>
                        </div>
                    </div>
                    <div className="cyber-down-btn text-center position-relative d-none d-lg-block">
                        <a href="#cyber-about" className="text-primary">
                            <i className="far fa-arrow-down"></i>
                        </a>
                    </div>
                </div>
            </section>

            <section className="counter-with-video pt-80 pb-120" id="cyber-about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="cyber-about-img text-center mb-30 mb-lg-0">
                                <img src={`${url}assets/img/about_cyber.png`} alt="VR" className="img-fluid" />
                                <div className="row g-0">
                                    <div className="col-lg-5">
                                        <div className="sheild-img">
                                            <img src={`${url}assets/img/about2.png`} alt="Sheild" className="img-fluid d-none d-lg-block" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="pe-2">
                                            <div className="cyber-about-count-box d-md-flex bg-white p-4 mt-3">
                                                <div className="pe-3">
                                                    <h2>20+</h2>
                                                </div>
                                                <div>
                                                    <h5 className="h6">Years Experience</h5>
                                                    <p className="mb-0">We Just Big Achived</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="pt-5">
                                <div className="section-heading mb-5">
                                    <h5 className="h6 text-primary">About Xampra</h5>
                                    <h2>Want to know the secret to increasing your brand awareness?</h2>
                                    <h3>Content is key!</h3>
                                    <p>
                                    In the ever-evolving landscape of digital marketing, creating high-quality content is crucial for achieving a maximum return on investment (ROI). With fierce competition, it is essential to stand out and establish a strong brand identity that resonates with your target audience. By delivering top-notch content, you can attract new prospects, foster trust with potential clients, and ensure your brand's unique story is effectively communicated. At our company, we understand the importance of quality content and are here to help you succeed in your marketing endeavors.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="feature-section ptb-120 bg-light cont">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-heading text-center">
                                <h2>Benefits of Content Marketing</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="feature-grid">
                                <div className="feature-card border border-light border-2 rounded-custom p-5">
                                    <div className="rounded mb-2 d-flex align-items-center">
                                        <h3 className="h5 mb-0">Can strengthen your SEO and SEM</h3>
                                    </div>
                                    <div className="feature-content">
                                        <p className="mb-0">
                                            Because of the mass shift towards digital marketing, becoming invisible on the search engine page is a problem that many small businesses face. That’s why they are using SEO and SEM to increase brand visibility as part of their marketing plan. However, no search marketing strategy can be successful without content. Creating written content, like blog posts, gives you the opportunity to use specific keywords that your target audience is searching for, and allow you to rank higher on SERPs such as Google
                                        </p>
                                    </div>
                                </div>

                                <div className="feature-card border border-light border-2 rounded-custom p-5">
                                    <div className="rounded mb-2 d-flex align-items-center">
                                        <h3 className="h5 mb-0">Cost effective</h3>
                                    </div>
                                    <div className="feature-content">
                                        <p className="mb-0">
                                            Compared to other digital marketing strategies, content marketing can be just as inexpensive as it is highly-effective! In fact, studies show that content marketing costs 62% less than traditional marketing and generates about 3 times as many leads. Having high-quality content provides fantastic results that essentially work to pay the price that it takes to invest, and more!                                        </p>
                                    </div>
                                </div>


                                <div className="feature-card border border-light border-2 rounded-custom p-5">
                                    <div className="rounded mb-2 d-flex align-items-center">
                                        <h3 className="h5 mb-0">Content can be targeted </h3>
                                    </div>
                                    <div className="feature-content">
                                        <p className="mb-0">
                                            You don’t want just anyone on your website; you’re looking to reach specific, qualified leads who are likely to end up purchasing your products and services. Content marketing involves creating content that speaks to members of your target audience, and appeals to their needs, wherever they are most likely to be found on the web
                                        </p>
                                    </div>
                                </div>

                                <div className="feature-card border border-light border-2 rounded-custom p-5">
                                    <div className="rounded mb-2 d-flex align-items-center">
                                        <h3 className="h5 mb-0">Allows you to get creative</h3>
                                    </div>
                                    <div className="feature-content">
                                        <p className="mb-0">
                                            Content marketing doesn’t have to be limited to putting some words on a page. In fact, it shouldn’t be that simple. Today, the Internet has made it possible for businesses to reach new prospects and leads using various channels, like social media. Therefore, it is necessary that your team is creating branded content that uniquely appeals to each medium that you’re marketing on. You can do this by creating YouTube videos, graphics, social media posts, eBooks, and even podcasts!
                                        </p>
                                    </div>
                                </div>


                                <div className="feature-card border border-light border-2 rounded-custom p-5">
                                    <div className="rounded mb-2 d-flex align-items-center">
                                        <h3 className="h5 mb-0">Increase brand awareness by building a strong presence online</h3>
                                    </div>
                                    <div className="feature-content">
                                        <p className="mb-0">
                                            By creating content that is informative, educational, and engaging, you’re building a strong brand identity. When you have a strong brand identity, you are in a better position to form relationships with potential-customers that are based on trust and understanding. This allows you to set your business apart from your competitors, reach new prospects and leads, and ultimately improve your conversion rate
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cyber-features pt-100 bg-dark1">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6">
                            <div className="section-heading text-white text-center mb-5">
                                <h2 className="text-white">What’s included in our content creation services?</h2>
                                <p>
                                  At Xampra, we recognize that every marketing campaign is unique and requires tailored content solutions. Our team of experts excels at producing various types of content that align with your brand and specific marketing objectives. Whether you need compelling blog articles, engaging social media posts, captivating videos, or informative whitepapers, we have the expertise to create high-quality content that resonates with your target audience and drives results.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="cyber-single-service bg-white rounded-custom mb-30 comin">
                                <div className="cyber feature-info-wrap">
                                    <h3 className="h5">Blog Posts</h3>
                                    <p>
                                        Maintaining an active blog for your business is crucial if you want to establish a strong brand identity. Why? Well, when you consistently provide your audience with blog posts that contain useful information that is relevant to their interests, you are nurturing them through their buyer’s journey, and positioning your brand as a credible source for all things related to the products and services that they are looking to purchase. Building these trust-based relationships with your audience will give you an advantage over your competitors when potential clients are considering where to invest.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cyber-single-service bg-white rounded-custom mb-30 comin">
                                <div className="cyber feature-info-wrap">
                                    <h3 className="h5">Web Pages</h3>
                                    <p>
                                        You probably already know that content marketing is a great way to tell your brand’s story. That being said, having well-designed web pages is an important first-step in making sure that you are sharing your brand’s message in the best possible way. Your website is likely the first place that your potential customers are interacting with your brand, and first impressions matter. Provide new prospects and leads with a positive user experience by ensuring that your website excels in performance and appearance. Designing your website to showcase exactly who you are will set you apart from competitors, and further establish your unique brand identity.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cyber-single-service bg-white rounded-custom mb-30 comin">
                                <div className="cyber feature-info-wrap">
                                    <h3 className="h5">Email Marketing</h3>
                                    <p>
                                        Whether you’re looking to reach out to potential customers as part of a retargeting strategy or simply looking to enhance your relationship with current clients, email marketing can be a powerful tactic in your content marketing plan. Incorporating email marketing into your marketing campaign gives you the opportunity to keep members of your target audience informed and engaged with your brand by distributing useful content directly to their inbox.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="cyber-single-service bg-white rounded-custom mb-30 comin">
                                <div className="cyber feature-info-wrap">
                                    <h3 className="h5">Marketing Assets</h3>
                                    <p>
                                        In addition to the types of content listed above, your business can also utilize a variety of other content marketing assets that are best suited for your unique marketing campaign. Some of these include digital brochures, eBooks, infographics, podcasts, videos, newsletters, advertisements, and so much more!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




        </>
    )
}
export default ContentCreation
