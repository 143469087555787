import React, {useEffect} from "react";
import $ from 'jquery';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Link} from "react-router-dom";
import {contact,url,SetAppointmentUrl} from "../constants/helpers";
import {Helmet} from "react-helmet";
import { InlineWidget } from "react-calendly";
import { PopupWidget } from "react-calendly";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return(
        <>
            <Helmet>
                <title>About Us | Xampra One Stop IT Solutions</title>
                <meta name="description" content="We Care Your any IT SolutionMake your work easier with an integrated ecosystem that lets all departments work properly together."/>
                <meta name="keywords" content="Website Development, Web Application Development, Android App Development"/>
                <meta name="author" content="Abdul Hanan"/>
                <link rel= "canonical" href= "https://www.xampra.com/about" />
            </Helmet>
            <div className="bg-dark1">
            <section className="about-header-section ptb-120 position-relative overflow-hidden text-white bg7">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading-wrap d-flex justify-content-between z-5 position-relative">
                                <div className="about-content-left">
                                    <div className="about-info mb-5">
                                        <h1 className="fw-bold display-5 text-white">Elevate Business Growth & Delight Customers with Xampra's Distinct Solutions</h1>
                                        <p className="lead">Revolutionize industries by leveraging technically advanced technologies with exceptional quality, fostering error-free communities through dynamic disintermediation.</p>
                                        
                                        <Link to={"../"+SetAppointmentUrl} className="btn btn-primary mt-4 me-3">Start With Us</Link>
                                    </div>
                                    <img src={`${url}assets/img/about-img-1.webp`} alt="about" className="img-fluid about-img-first mt-5 rounded-custom shadow"/>
                                </div>
                                <div className="about-content-right">
                                    <img src={`${url}assets/img/about-img-2.webp`} alt="about" className="img-fluid mb-5 rounded-custom shadow"/>
                                    <img src={`${url}assets/img/about-img-3.webp`} alt="about" className="rounded-custom about-img-last shadow"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-white position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z-2 py-5">
                </div>
            </section>

            </div>

            <section className="our-story-section pt-60 pb-120 bg8" >
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-5 col-md-12 order-lg-1">
                            <div className="section-heading sticky-sidebar">
                                <h4 className="h5 text-primary">Our Story</h4>
                                <h2>A Great Story Starts with a Friendly Team</h2>
                                <p>Deliver principle-centered e-business solutions, harnessing dynamic quality vectors across cross-media platforms. Prioritize proactive outsourcing and leverage the vertical technology leadership of other companies.</p>
                                <div className="mt-4">
                                    <h6 className="mb-3">We Are Awarded By </h6>
                                    <img src={`${url}assets/img/awards-01.svg`} alt="awards" className="me-4 img-fluid"/>
                                        <img src={`${url}assets/img/awards-02.svg`} alt="awards" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 order-lg-0">
                            <div className="story-grid-wrapper position-relative">

                                <ul className="position-absolute animate-element parallax-element shape-service d-none d-lg-block">
                                    <li className="layer" data-depth="0.02">
                                        <img src={`${url}assets/img/color-shape/image-2.svg`} alt="shape" className="img-fluid position-absolute color-shape-2 z-5"/>
                                    </li>
                                    <li className="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/color-shape/feature-3.svg`} alt="shape" className="img-fluid position-absolute color-shape-3"/>
                                    </li>
                                </ul>

                                <div className="story-grid rounded-custom bg-dark overflow-hidden position-relative">
                                    <div className="story-item bg-light border">
                                        <h3 className="display-5 fw-bold mb-1 text-success">550K+</h3>
                                        <h6 className="mb-0">Active Users</h6>
                                    </div>
                                    <div className="story-item bg-white border">
                                        <h3 className="display-5 fw-bold mb-1 text-primary">40+</h3>
                                        <h6 className="mb-0">Team Members</h6>
                                    </div>
                                    <div className="story-item bg-white border">
                                        <h3 className="display-5 fw-bold mb-1 text-dark">$20M+</h3>
                                        <h6 className="mb-0">Revenue Per/Year</h6>
                                    </div>
                                    <div className="story-item bg-light border">
                                        <h3 className="display-5 fw-bold mb-1 text-warning">8 Years</h3>
                                        <h6 className="mb-0">In Business</h6>
                                    </div>
                                    <div className="story-item bg-light border">
                                        <h3 className="display-5 fw-bold mb-1 text-danger">425+</h3>
                                        <h6 className="mb-0">Clients Worldwide</h6>
                                    </div>
                                    <div className="story-item bg-white border">
                                        <h3 className="display-5 fw-bold mb-1 text-primary">855+</h3>
                                        <h6 className="mb-0">Projects Completed</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-section ptb-120">
                <div className="container">
                    <div className="feature-color bg-primary-soft px-5 rounded-custom position-relative">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-5 col-md-12">
                                <div className="feature-content-wrap pe-lg-4 ptb-60 p-lg-0 mb-5 mb-lg-0">
                                    <h5 className="text-primary h6 fw-bold">What We Think</h5>
                                    <h2>Best Features that Help you Build Quality for Your Business</h2>
                                    <p>
                                    Empowering organizations, we assist in recognizing untapped market opportunities, formulating a sustainable vision, and charting a future roadmap. Through comprehensive documentation, we enable the creation of the desired organization. Our primary objective is to ensure customer satisfaction, enhancing client experiences and equipping teams with cutting-edge digital tools.
                                    </p>

                                    <Link to={contact} className="btn btn-primary mt-4">Learn More</Link>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div
                                    className="row align-items-center justify-content-center position-relative mt--100 z-2">
                                    <div className="col-md-6">
                                        <div className="cta-card rounded-custom text-center shadow p-5 bg-white my-4">
                                            <div className="feature-icon d-inline-block bg-dark rounded mb-4">
                                                <i className="fal fa-bezier-curve text-white fa-2x"></i>
                                            </div>
                                            <h3 className="h5 fw-bold">Dependable Solutions</h3>
                                            <p className="mb-0">Unleashing Software that Excels in Security, Usability, Efficiency, and Maintainability.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="cta-card rounded-custom text-center shadow p-5 bg-white my-4">
                                            <div className="feature-icon d-inline-block bg-danger rounded mb-4">
                                                <i className="fal fa-bezier-curve text-white fa-2x"></i>
                                            </div>
                                            <h3 className="h5 fw-bold">Integration</h3>
                                            <p className="mb-0">Collaborating with APIs, Message Queues, Socket Programming, and Seamless Database Interactions.</p>
                                        </div>
                                        <div className="cta-card rounded-custom text-center shadow p-5 bg-white my-4">
                                            <div className="feature-icon d-inline-block bg-success rounded mb-4">
                                                <i className="fal fa-bezier-curve text-white fa-2x"></i>
                                            </div>
                                            <h3 className="h5 fw-bold">Performance</h3>
                                            <p className="mb-0">Delivering Software Systems with Unwavering Reliability and Precise Results.</p>
                                        </div>
                                    </div>
                                    <ul className="position-absolute animate-element parallax-element z--1 dfff">
                                        <li className="layer lay" data-depth="0.06" >
                                            <img src={`${url}assets/img/shape/shape-bg-3.svg`} alt="shape"
                                                 className="img-fluid"/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-section-two ptb-120 bg-light">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="section-heading">
                                <h4 className="h5 text-primary">Our Values</h4>
                                <h2>The Core Values that Drive Everything</h2>
                                <p>Accelerate Channel Incubation: Crafting Multidisciplinary Architectures for Exceptional Innovation.</p>
                                <ul className="list-unstyled mt-5">
                                    <li className="d-flex align-items-start mb-4">
                                        <div className="icon-box bg-primary rounded me-4">
                                            <i className="fas fa-bezier-curve text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">The Art of Pixel-Perfect Design</h3>
                                            <p>
                                            In the realm of web design, graphic design, and user interface design, the objective of pixel-perfect design is to achieve a visually flawless and exacting visual composition. Every element is meticulously positioned and aligned to a specific grid, ensuring a level of precision that enhances the overall aesthetic and user experience.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-start mb-4">
                                        <div className="icon-box bg-danger rounded me-4">
                                            <i className="fas fa-fingerprint text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">UCrafting Clean and Impactful Designs</h3>
                                            <p>
                                            In this design approach, the emphasis lies in the removal of unnecessary elements, utilizing a minimal color palette, and incorporating clean and simple lines. The goal is to create a design that is visually striking yet uncluttered, allowing for easy comprehension. By prioritizing simplicity and visual impact, designers achieve a harmonious balance between elegance and clarity.
                                            </p>
                                        </div>
                                    </li>
                                    <li className="d-flex align-items-start mb-4">
                                        <div className="icon-box bg-dark rounded me-4">
                                            <i className="fas fa-cog text-white"></i>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="h5">Embracing Diverse Perspectives for Market Expansion</h3>
                                            <p>
                                                In the pursuit of market expansion, our approach is to facilitate seamless transitions and foster prospective collaborations, transcending focused niche markets. By embracing diverse perspectives and encouraging idea-sharing, we create a dynamic ecosystem that thrives on innovation and collective growth. Our goal is to break down barriers and explore new possibilities, paving the way for a broader reach and sustained success.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-7">
                            <div className="feature-img-wrap position-relative d-flex flex-column align-items-end">
                                <ul className="img-overlay-list list-unstyled position-absolute">
                                    <li className="d-flex align-items-center bg-white rounded shadow-sm p-3">
                                        <i className="fas fa-check bg-primary text-white rounded-circle"></i>
                                        <h6 className="mb-0">Schedule Your Meeting</h6>
                                    </li>
                                    <li className="d-flex align-items-center bg-white rounded shadow-sm p-3">
                                        <i className="fas fa-check bg-primary text-white rounded-circle"></i>
                                        <h6 className="mb-0">We Analysis Your Requirements </h6>
                                    </li>
                                    <li className="d-flex align-items-center bg-white rounded shadow-sm p-3">
                                        <i className="fas fa-check bg-primary text-white rounded-circle"></i>
                                        <h6 className="mb-0">Start Working With Us</h6>
                                    </li>
                                </ul>
                                <img src={`${url}assets/img/feature-img3.webp`} alt="feature image" className="img-fluid rounded-custom"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="office-address-section ptb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="section-heading text-center">
                                <h4 className="h5 text-primary">Our Office</h4>
                                <h2>Located Around the World</h2>
                                <p>Dynamically technically sound technologies with parallel task convergence quality vectors
                                    through excellent relationships. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 mt-xl-0">
                            <div className="rounded-custom border d-block office-address overflow-hidden z-2 bg3">
                                <div className="office-content text-center p-4">
                                    <span className="office-overlay"></span>
                                    <div className="office-info">
                                        <h5>Singapore</h5>
                                        <address>
                                            4636 Bombardier Way <br/> Sr,Worthington, <br/>California(CA), 94102
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 mt-xl-0">
                            <div className="rounded-custom border d-block office-address overflow-hidden z-2 bg5" >
                                <div className="office-content text-center p-4">
                                    <span className="office-overlay"></span>
                                    <div className="office-info">
                                        <h5>New York</h5>
                                        <address>
                                            4219 Snowbird Lane <br/> St Carthage, <br/>New York(NY), 13619
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 mt-4 mt-lg-0 mt-xl-0">
                            <div className="rounded-custom border d-block office-address overflow-hidden z-2 bg6">
                                <div className="office-content text-center p-4">
                                    <span className="office-overlay"></span>
                                    <div className="office-info">
                                        <h5>Barlin City</h5>
                                        <address>
                                            Brandenburgische Straße <br/> DE. Berlin Kreuzberg, <br/>Berlin(CA), 10997
                                        </address>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
export default About
