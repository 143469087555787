import React from "react";
import {Helmet} from "react-helmet";
import { InlineWidget } from "react-calendly";
import {url} from "../constants/helpers";

const SetAppointment = () => {
    return(
        <>
            <Helmet>
                <title>Set Meeting with Us | Xampra One Stop IT Solutions</title>
                <meta name="description" content="We Care Your any IT SolutionMake your work easier with an integrated ecosystem that lets all departments work properly together. Set Meeting With Us"/>
                <meta name="keywords" content="Website Development, Web Application Development, Android App Development"/>
                <meta name="author" content="Abdul Hanan"/>
                <link rel= "canonical" href= "https://www.xampra.com/SetMeeting" />
            </Helmet>

            

            <section className="contact-us-form pt-60 pb-120 bg9">
                <div className="container">
                    <div className="row justify-content-lg-between align-items-center">
                        <div className="col-lg-6 col-md-8">
                            <div className="section-heading">
                                <h2>Talk to Our Web & Mobile App Department Team</h2>
                                <p>Collaboratively promote client-focused convergence vis-a-vis customer directed alignments via
                                    standardized infrastructures.</p>
                            </div>
                            <InlineWidget url="https://calendly.com/xamprasoftwarehouse" />
                        </div>
                        <div className="col-lg-5 col-md-10">
                            <div className="contact-us-img">
                                <img src={`${url}meeting.png`} alt="contact us" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
} 
export default SetAppointment;