import React,{useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {Link} from "react-router-dom";
import {contact,url,SetAppointmentUrl} from "../constants/helpers";
import IntigrationList from "../components/IntigrationList";
import {Helmet} from "react-helmet";
import {WebSerices} from "../constants/helpers";
const MobileAppDevelopment = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let data = WebSerices.find(el => el.id === "1");
    return(
        <>
            <Helmet>
                <title> {data.metaTitle}</title>
                <meta name="description" content={data.shortDesc}/>
                <meta name="keywords" content={data.metaKeyword}/>
                <meta name="author" content="Abdul Hanan"/>
                <link rel= "canonical" href= "https://www.xampra.com/Mobile-Application-Development" />
            </Helmet>
            <div className="bg-dark1">
            <section className="hero-section ptb-120 text-white bg16">
                <div className="container">
                    <div className="row justify-content-center text-center text-lg-start align-items-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0">
                                <h1 className="fw-bold text-white display-5" data-aos="fade-up">Mobile Apps We Develop</h1>
                                <p className="lead" data-aos="fade-up">Crafting iOS & Android Apps as Web Solution Amplifiers. </p>
                                <div className="action-btns mt-5" data-aos="fade-up" data-aos-delay="50">
                                    <Link to={"../"+SetAppointmentUrl} className="btn btn-primary me-lg-3 me-sm-3">Book an Appointment</Link>
                                    <Link to={contact} className="btn btn-outline-light">Contact Us</Link>
                                </div>
                                <ul className="nav subscribe-feature-list d-flex justify-content-center justify-content-lg-start w-100 mt-3" data-aos="fade-up" data-aos-delay="100">
                                    <li className="nav-item">
                                        <span className="ms-0"><i className="far fa-check-circle text-primary me-2"></i>24/7 Support</span>
                                    </li>
                                    <li className="nav-item">
                                        <span><i className="far fa-check-circle text-primary me-2"></i>No credit required</span>
                                    </li>
                                </ul>

                                <div className="d-flex justify-content-center justify-content-lg-start mt-5" data-aos="fade-up" data-aos-delay="150">
                                    <img src={`${url}assets/img/awards-01.svg`} alt="awards" className="me-4 img-fluid"/>
                                        <img src={`${url}assets/img/awards-02.svg`} alt="awards" className="img-fluid"/>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-8">
                            <div className="hero-img-wrap position-relative app-screen-bg mt-5bg17" data-aos="fade-up" data-aos-delay="200">

                                <ul className="position-absolute animate-element parallax-element shape-service d-none d-lg-block">
                                    <li className="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/color-shape/image-4.svg`} alt="shape" className="img-fluid position-absolute color-shape-1"/>
                                    </li>
                                    <li className="layer" data-depth="0.02">
                                        <img src={`${url}assets/img/color-shape/feature-2.svg`} alt="shape" className="img-fluid position-absolute color-shape-2 z-5"/>
                                    </li>
                                    <li className="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/color-shape/feature-3.svg`} alt="shape" className="img-fluid position-absolute color-shape-3"/>
                                    </li>
                                </ul>
                                <img src={`${url}assets/img/screen/app-screen-on-hand.png`} alt="hero image" className="img-fluid position-relative z-5"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
            <section className="feature-section ptb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="section-heading text-center" data-aos="fade-up">
                                <h4 className="text-primary h5">Build Quality</h4>
                                <h2>Xampra's Comprehensive App Development Solutions</h2>
                                <p>
                                Xampra embraces the opportunity to contribute at various stages of development and offers both specialized assistance and complete end-to-end implementation for mobile applications. Our mobile app development services encompass a wide range of capabilities, ensuring a seamless and comprehensive approach to crafting exceptional mobile experiences.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center pt-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative" data-aos="fade-up" data-aos-delay="50">
                                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                    <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/consulting.svg`} alt="shape"/>
                                    </div>
                                    <h3 className="h5">Mobile consulting</h3>
                                    <p className="mb-0">
                                        Our Consultants Shape Concepts, Optimize Compatibility, Plan, and Maximize Efficiency.
                                    </p>
                                </div>
                                <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                    <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative" data-aos="fade-up" data-aos-delay="100">
                                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0">
                                    <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/design.svg`} alt="shape"/>
                                    </div>
                                    <h3 className="h5">Mobile UX and UI design</h3>
                                    <p className="mb-0">
                                        Xampra Designers Craft Seamless and Intuitive Interfaces for Enhanced Conversion, Engagement, and Adoption.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative" data-aos="fade-up" data-aos-delay="150">
                                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0">
                                    <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/mobile.svg`} alt="shape"/>
                                    </div>
                                    <h3 className="h5">Mobile app development</h3>
                                    <p className="mb-0">
                                        Track Record of 350+ Projects, Create and Deploy Mobile Apps that Excel in Speed, Stability, and Performance.
                                    </p>
                                </div>
                                <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                                    <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center pt-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative" data-aos="fade-up" data-aos-delay="50">
                                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                    <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/back-end.svg`} alt="shape"/>
                                    </div>
                                    <h3 className="h5">Back end development</h3>
                                    <p className="mb-0">
                                    Our Back-End Developers Construct Secure Mobile Back Ends, Facilitating Efficient and Rapid Data Synchronization and Transfer.
                                    </p>
                                </div>
                                <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                    <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative" data-aos="fade-up" data-aos-delay="100">
                                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0">
                                    <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/web-and-mobile.svg`} alt="shape"/>
                                    </div>
                                    <h3 className="h5">Web development</h3>
                                    <p className="mb-0">
                                    Xampra Enhances Your Mobile App with Complementary including Web Apps, Web Portals, Online Stores, and SaaS Solutions.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative" data-aos="fade-up" data-aos-delay="150">
                                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0">
                                    <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/integration (1).svg`} alt="shape"/>
                                    </div>
                                    <h3 className="h5">Mobile app integration</h3>
                                    <p className="mb-0">
                                    Leveraging Extensive API Knowledge, We Ensure Effortless Integration with Back-End Systems and Third-Party Software.                                        
                                    </p>
                                </div>
                                <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                                    <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center pt-4">
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative" data-aos="fade-up" data-aos-delay="50">
                                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2">
                                    <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/testing.svg`} alt="shape"/>
                                    </div>
                                    <h3 className="h5">Mobile app QA and testing</h3>
                                    <p className="mb-0">
                                    Our ISTQB-Certified Engineers Conduct Rigorous Assessments, Including Functional, Performance, Security, UX, and Accessibility Testing.
                                    </p>
                                </div>
                                <div className="dot-shape-bg position-absolute z--1 left--40 top--40">
                                    <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative" data-aos="fade-up" data-aos-delay="100">
                                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0">
                                    <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/support_1.svg`} alt="shape"/>
                                    </div>
                                    <h3 className="h5">Maintenance and support</h3>
                                    <p className="mb-0">
                                    ScienceSoft Provides L0-L3 Support, Ensures Compliance, Enhances Security, Manages Performance, and Drives App Evolution.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="position-relative" data-aos="fade-up" data-aos-delay="150">
                                <div className="cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0">
                                    <div className="feature-icon d-inline-block bg-primary-soft rounded-circle mb-32">
                                        <img src={`${url}assets/img/improve.svg`} alt="shape"/>
                                    </div>
                                    <h3 className="h5">Mobile app modernization</h3>
                                    <p className="mb-0">
                                    Our Developers Expertly Convert Websites to Mobile Apps, Revamp Architectures, and Refactor Code with Cutting-Edge Technologies.
                                    </p>
                                </div>
                                <div className="dot-shape-bg position-absolute z--1 right--40 bottom--40">
                                    <img src={`${url}assets/img/shape/dot-big-square.svg`} alt="shape"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="feature-section pt-60 pb-120">
                <div className="container">
                    <div className="row align-items-lg-center justify-content-between">
                        <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
                            <div className="mb-4" data-aos="fade-up">
                                <h2>Challenges We Solve</h2>
                                <p>
                                Our Developers Embrace Cutting-Edge Requirements and Guidelines, Ensuring Exceptional Professional iOS App Development Services while Overcoming Key Challenges.
                                </p>
                            </div>
                            <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5" data-aos="fade-up">
                                <li>
                                    <span className="d-block mb-4"><i className="fal fa-computer-classic fa-2x text-primary"></i></span>
                                    <h3 className="h5">Cross-device compatibility</h3>
                                </li>
                                <li>
                                    <span className="d-block mb-4"><i className="fal fa-dashboard fa-2x text-primary"></i></span>
                                    <h3 className="h5">Performance and memory limitations</h3>
                                </li>
                                <li>
                                    <span className="d-block mb-4"><i className="fal fa-battery fa-2x text-primary"></i></span>
                                    <h3 className="h5">Battery consumption</h3>
                                </li>
                                <li>
                                    <span className="d-block mb-4"><i className="fal fa-network-wired fa-2x text-primary"></i></span>
                                    <h3 className="h5">Network speed, etc.</h3>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <div className="pr-lg-4 position-relative" data-aos="fade-up">

                                <ul className="position-absolute animate-element parallax-element shape-service z--1 hide-medium">
                                    <li className="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/color-shape/image-2.svg`} alt="shape" className="img-fluid position-absolute color-shape-1"/>
                                    </li>
                                    <li className="layer" data-depth="0.02">
                                        <img src={`${url}assets/img/color-shape/feature-2.svg`} alt="shape" className="img-fluid position-absolute color-shape-2 z-5"/>
                                    </li>
                                    <li className="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/color-shape/feature-3.svg`} alt="shape" className="img-fluid position-absolute color-shape-3"/>
                                    </li>
                                </ul>

                                <div className="bg-light text-center rounded-custom overflow-hidden pt-5 px-3 px-lg-5 mx-lg-auto">
                                    <div className="mb-5">
                                        <h3 className="fw-medium h4">
                                            If you want to improve your existing mobile app or develop a completely new one, an expert review may be a good place to start.
                                        </h3>
                                    </div>
                                    <div className="position-relative w-75 mx-auto">
                                        <img className="position-relative z-2 w-100 h-auto" src="assets/img/screen/half-iphone.svg" alt="Image Description"/>
                                            <img className="position-absolute half-screen" src="assets/img/screen/app-screen-1.png" alt="Image Description"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="services-section ptb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="section-heading text-center">
                                <h4 className="h5 text-primary">Services</h4>
                                <h2>Mobile Apps We Develop</h2>
                                <p>
                                    We provide professional development of the following app types:
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="position-relative w-100">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="feature-grid">
                                            <div className="feature-card border border-light border-2 rounded-custom p-5">
                                                <div className="rounded mb-2 d-flex align-items-center">
                                                    <img src={`${url}assets/img/mobile-banking-app.svg`} width={"120px"} />
                                                    <h3 className="h5 mb-0">Mobile banking, billing apps, wallets</h3>
                                                </div>
                                            </div>

                                            <div className="feature-card border border-light border-2 rounded-custom p-5">
                                                <div className="rounded mb-2 d-flex align-items-center">
                                                    <img src={`${url}assets/img/customer-portal-green.svg`} width={"120px"} />
                                                    <h3 className="h5 mb-0">Patient and hospital apps</h3>
                                                </div>
                                            </div>

                                            <div className="feature-card border border-light border-2 rounded-custom p-5">
                                                <div className="rounded mb-2 d-flex align-items-center">
                                                    <img src={`${url}assets/img/telemedicine-app.svg`} width={"120px"} />
                                                    <h3 className="h5 mb-0">
                                                        Telehealth apps
                                                    </h3>
                                                </div>
                                            </div>


                                            <div className="feature-card border border-light border-2 rounded-custom p-5">
                                                <div className="rounded mb-2 d-flex align-items-center">
                                                    <img src={`${url}assets/img/communication.svg`} width={"120px"} />
                                                    <h3 className="h5 mb-0">
                                                        Messaging and VoIP apps
                                                    </h3>
                                                </div>
                                            </div>

                                            <div className="feature-card border border-light border-2 rounded-custom p-5">
                                                <div className="rounded mb-2 d-flex align-items-center">
                                                    <img src={`${url}assets/img/iptv.svg`} width={"120px"} />
                                                    <h3 className="h5 mb-0">
                                                        IPTV and SVOD apps
                                                    </h3>
                                                </div>
                                            </div>


                                            <div className="feature-card border border-light border-2 rounded-custom p-5">
                                                <div className="rounded mb-2 d-flex align-items-center">
                                                    <img src={`${url}assets/img/shopping-cart.svg`} width={"120px"} />
                                                    <h3 className="h5 mb-0">
                                                        m-Commerce apps
                                                    </h3>
                                                </div>
                                            </div>



                                            <div className="feature-card border border-light border-2 rounded-custom p-5">
                                                <div className="rounded mb-2 d-flex align-items-center">
                                                    <img src={`${url}assets/img/data-analytics.svg`} width={"120px"} />
                                                    <h3 className="h5 mb-0">
                                                        Field service and mobile BI
                                                    </h3>
                                                </div>
                                            </div>



                                            <div className="feature-card border border-light border-2 rounded-custom p-5">
                                                <div className="rounded mb-2 d-flex align-items-center">
                                                    <img src={`${url}assets/img/booking.svg`} width={"120px"} />
                                                    <h3 className="h5 mb-0">
                                                        Booking and ticketing apps
                                                    </h3>
                                                </div>
                                            </div>


                                            <div className="feature-card border border-light border-2 rounded-custom p-5">
                                                <div className="rounded mb-2 d-flex align-items-center">
                                                    <img src={`${url}assets/img/ar.svg`} width={"120px"} />
                                                    <h3 className="h5 mb-0">Augmented reality apps</h3>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-section pt-60 pb-120">
                <div className="container">
                    <div className="row align-items-lg-center justify-content-between">
                        <div className="col-lg-5 mb-7 mb-lg-0">
                            <div className="mb-4" data-aos="fade-up">
                                <h2>Mobile Ecommerce App Development</h2>
                                <p>
                                Xampra Crafts Comprehensive Customer Journey Experiences, Seamlessly Combining Features to Support Specific Stages (e.g., Catalog + Store) and Delivering Targeted Apps for Unique Ecommerce Requirements. Empower Your Vision by Customizing the Feature Set of Your Ecommerce App.
                                </p>
                            </div>
                            <ul className="list-unstyled d-flex flex-wrap list-two-col mt-5" data-aos="fade-up">
                                <li>
                                    <span className="d-block mb-4">
                                        <img src={`${url}assets/img/store.svg`} width={"70px"}/>
                                    </span>
                                    <h3 className="h5">Stores and marketplaces</h3>
                                    <p>
                                    Enhance Your Web Store with an Exceptional Mobile App, Elevating Your Reach and Amplifying Customer Engagement.
                                    </p>
                                </li>
                                <li>
                                    <span className="d-block mb-4">
                                        <img src={`${url}assets/img/catalog.svg`} width={"70px"}/>
                                    </span>
                                    <h3 className="h5">Catalogs and showrooms</h3>
                                    <p>
                                    Leverage High-Quality Visuals, 360° Product Previews, and AR Try-On Experiences to Enhance Customer Trust and Drive Increased Sales.
                                    </p>
                                </li>
                                <li>
                                    <span className="d-block mb-4">
                                        <img src={`${url}assets/img/loyalty.svg`} width={"70px"}/>
                                    </span>
                                    <h3 className="h5">Loyalty solutions</h3>
                                    <p>
                                    Foster Repeat Business by Providing Digital Coupons, Bonus Point Accounts, and Interactive Maps featuring Chain and Partner Stores.
                                    </p>
                                </li>
                                <li>
                                    <span className="d-block mb-4">
                                        <img src={`${url}assets/img/purchase-support.svg`} width={"70px"}/>
                                    </span>
                                    <h3 className="h5">Post-purchase support</h3>
                                    <p>
                                    Assist Clients in Product Installation and Maintenance through Engaging Videos or AR, and Provide Real-time Support via Chatbots and Messaging Channels.
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6">
                            <div className="pr-lg-4 position-relative" data-aos="fade-up">

                                <ul className="position-absolute animate-element parallax-element shape-service z--1 hide-medium">
                                    <li className="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/color-shape/image-3.svg`} alt="shape" className="img-fluid position-absolute color-shape-1"/>
                                    </li>
                                    <li className="layer" data-depth="0.02">
                                        <img src={`${url}assets/img/color-shape/feature-1.svg`} alt="shape" className="img-fluid position-absolute color-shape-2 z-5"/>
                                    </li>
                                    <li className="layer" data-depth="0.03">
                                        <img src={`${url}assets/img/color-shape/feature-3.svg`} alt="shape" className="img-fluid position-absolute color-shape-3"/>
                                    </li>
                                </ul>
                                <div className="bg-light text-center shadow-sm rounded-custom overflow-hidden pt-5 px-3 px-lg-5 mx-lg-auto">
                                    <div className="mb-5">
                                        <h3 className="fw-medium h4">We deliver best quality app for your business</h3>
                                    </div>
                                    <div className="position-relative w-75 mx-auto">
                                        <img className="position-relative z-2 w-100 h-auto" src="assets/img/screen/half-iphone.svg" alt="Image Description"/>
                                            <img className="position-absolute half-screen" src="assets/img/screen/app-screen-2.jpg" alt="Image Description"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <IntigrationList/>
        </>
    )
}
export default MobileAppDevelopment
